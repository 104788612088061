import io from 'socket.io-client'
import * as config from 'config'
import { stores } from 'data'
import bus from 'lib/bus'
import * as storage from 'lib/storage'

const { WS_URL } = config

const ONLINE_EVENTS = ['connect', 'reconnect']

const OFFLINE_EVENTS = [
  'reconnecting',
  'reconnect_failed',
  'reconnect_error',
  'connect_timeout',
  'disconnect',
  'error',
  'end'
]

export const CONNECTED = 'CONNECTED'
export const OFFLINE = 'OFFLINE'
export const SYNCING = 'SYNCING'
export const SYNCED = 'SYNCED'
export const ONLINE = 'ONLINE'

export const state = {
  online: true,
  socket: null,
  domain: null,
  rooms: []
}

const ADMIN_ROOM = '*'

export const connect = async ({ domain = '', rooms } = {}) => {
  if (state.domain === domain && state.socket) {
    return state.socket
  }

  await close()

  const token = await storage.get('token')

  if (!token) return

  const options = {
    query: `token=${token}`,
    transports: ['websocket']
  }

  state.socket = io(`${WS_URL}/${domain}`, options)
  console.log('DOMAIN', `${WS_URL}/${domain}`)

  state.socket.on('reconnect', () => subscribe(state.rooms, true))
  state.socket.on('event', onEvent)
  state.domain = domain

  ONLINE_EVENTS.forEach(event => {
    state.socket.on(event, () => {
      if (state.online === true) return
      state.online = true
      bus.emit('online::change', true)
    })
  })

  OFFLINE_EVENTS.forEach(event => {
    state.socket.on(event, () => {
      if (state.online === false) return
      state.online = false
      bus.emit('online::change', false)
    })
  })

  await subscribe(ADMIN_ROOM)
  return state.socket
}

const onEvent = action => {
  const { domain } = storage.get('comunity', {})
  console.log('INCOMING EVENT', action, domain)
  Object.keys(stores).forEach(key => {
    const store = stores[key]
    if (store.event) store.event(action, domain)
  })
}

const diffRooms = rooms => {
  return state.rooms.reduce((acc, room) => {
    if (!rooms.includes(room)) acc.push(room)
    return acc
  }, [])
}

export const subscribe = async (rooms, force = false) => {
  if (!rooms) return

  if (Array.isArray(rooms)) {
    await unsubscribe(diffRooms(rooms))
    const promises = rooms.map(room => subscribe(room, force))
    return Promise.all(promises)
  }

  const room = rooms
  const socket = state.socket
  const isRoom = state.rooms.includes(room)

  if (!force && (!socket || isRoom)) return

  console.log(force, !!socket, isRoom, socket.id, room)

  socket.emit('subscribe', room)

  if (!isRoom) state.rooms.push(room)
}

export const unsubscribe = async rooms => {
  if (!rooms) return

  if (Array.isArray(rooms)) {
    const promises = rooms.map(unsubscribe)
    return Promise.all(promises)
  }

  const room = rooms
  const socket = state.socket

  if (!socket || !state.rooms.includes(room)) return

  socket.emit('unsubscribe', room)
  state.rooms = state.rooms.filter(r => r !== room)
}

export const close = async () => {
  if (!state.socket) return
  await unsubscribe(state.rooms)
  state.socket.off()
  state.socket.close()
  state.rooms = []
  state.domain = null
  state.socket = null
}
