import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Validator, Select } from 'components'
import { isRequired, isEmail, isSlug, isUrl } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'
import { withTheme } from 'hocs'

const noop = () => {}

const CommunityForm = ({ classes, onChange, isUpdate, data }) => {
  const rules = [
    runner('title', i18n`Title`, isRequired),
    runner('province', i18n`Province`, isRequired),
    runner('phone', i18n`Phone`, isRequired),
    runner('email', i18n`Email`, isEmail),
    runner('site', i18n`Site`, isUrl)
  ]

  const provinces = [
    { id: 'Alajuela', label: 'Alajuela' },
    { id: 'Cartago', label: 'Cartago' },
    { id: 'Heredia', label: 'Heredia' },
    { id: 'Guanacaste', label: 'Guanacaste' },
    { id: 'Limón', label: 'Limón' },
    { id: 'Puntarenas', label: 'Puntarenas' },
    { id: 'San José', label: 'San José' }
  ]

  const countries = [{ id: 'Costa Rica', label: 'Costa Rica' }]

  if (!isUpdate) {
    rules.push(
      runner('domain', i18n`Domain`, isRequired, isSlug),
      runner('country', i18n`Country`, isRequired)
    )
  }

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="title-field"
                label={i18n`Title`}
                value={data.title || ''}
                className={classes.textField}
                onChange={value(validate, 'title')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="domain-field"
                label={i18n`Domain`}
                value={data.domain}
                className={classes.textField}
                inputProps={{ readOnly: isUpdate }}
                onChange={isUpdate ? noop : value(validate, 'domain')}
                autoComplete="off"
              />
            </Grid>
            {isUpdate && (
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="country-field"
                  label={i18n`Country`}
                  value={data.country || 'Costa Rica'}
                  className={classes.textField}
                  inputProps={{ readOnly: isUpdate }}
                />
              </Grid>
            )}
            {!isUpdate && (
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  name="country"
                  id="country-field"
                  label={i18n`Country`}
                  data={countries}
                  value={data.country || ''}
                  onChange={validate}
                  className={classes.textField}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Select
                required
                name="province"
                id="province-field"
                label={i18n`Province`}
                data={provinces}
                value={data.province || ''}
                onChange={validate}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="address-field"
                label={i18n`Address`}
                value={data.address || ''}
                className={classes.textField}
                onChange={value(validate, 'address')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="phone-field"
                label={i18n`Phone`}
                value={data.phone || ''}
                className={classes.textField}
                onChange={value(validate, 'phone')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="emergencyPhone-field"
                label={i18n`Emergency Phone`}
                value={data.emergencyPhone || ''}
                className={classes.textField}
                onChange={value(validate, 'emergencyPhone')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="email-field"
                label={i18n`Email`}
                value={data.email || ''}
                className={classes.textField}
                onChange={value(validate, 'email')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="site-field"
                label={i18n`Website`}
                value={data.site || ''}
                className={classes.textField}
                onChange={value(validate, 'site')}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(CommunityForm)
