export default theme => ({
  card: {
    minWidth: 275,
    padding: `${theme.spacing(8)}px 0`
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  media: {
    padding: `${theme.spacing(4)}px 0`
  },
  actions: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  }
})
