import React, { Fragment, useReducer, useEffect } from 'react'
import { AssignDialog, DeleteDialog, Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { pick } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const ResidentDetail = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    assignDialog: false,
    deleteDialog: false
  })

  const { id, domain } = match.params
  const { isValid, deleteDialog, assignDialog } = state
  const resident = stores.residents.get(id)

  const isSameData =
    resident.name === state.data.name &&
    resident.phone === state.data.phone &&
    resident.active === state.data.active

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({
      errors: {},
      isValid: false,
      data: { code: 'none' }
    })
  }

  const fetch = async () => {
    try {
      await stores.residents.fetch(domain, { id })
    } catch (err) {
      toast.error(i18n`Unable to fetch resident information`)
      back()
    }
  }

  const update = async data => {
    try {
      await stores.residents.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update resident`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.residents.destroy(domain, data)
      toast.success(i18n`Deleted`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete resident`)
      }
    }
  }

  const assign = async units => {
    try {
      const data = units.map(unit => ({ unitId: unit.id, residentId: resident.id }))
      await stores.residents.assign(domain, data)
      toast.success(i18n`Assigned`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to assign resident`)
    }
    reset()
  }

  const unassign = async units => {
    try {
      const data = units.map(unit => ({ unitId: unit.id, residentId: resident.id }))
      await stores.residents.unassign(domain, data)
      toast.success(i18n`Unassigned`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to unassign resident`)
    }
    reset()
  }

  const back = () => {
    history.push(`/members/${domain}/residents`)
    return null
  }

  const onFormChange = (data, isValid, errors) => {
    data = pick(data, ['id', 'name', 'phone', 'active'])
    setState({ data, isValid, errors })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id: id })
    closeDeleteDialog()
  }

  const onAssignAction = ({ ok, ...rest }) => {
    console.log('RES =====', rest)
    if (ok && rest.assign.length) assign(rest.assign)
    if (ok && rest.unassign.length) unassign(rest.unassign)
    closeAssignDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openAssignDialog = () => {
    setState({ assignDialog: true })
  }

  const closeAssignDialog = () => {
    setState({ assignDialog: false })
  }

  if (!resident) return back()
  const selectedUnits = resident.units.map(unit => unit.id)

  const data = {
    ...resident,
    ...state.data,
    units: resident.units.map(unit => ` ${unit.title}`).sort()
  }

  return (
    <Fragment>
      <Section
        data={data}
        title={i18n`Resident Detail`}
        backButtonLabel={i18n`Residents`}
        onAssign={openAssignDialog}
        onDelete={openDeleteDialog}
        onCancel={back}
        onBack={back}
        onSubmit={onUpdate}
        disableSubmitButton={!isValid || isSameData}
      >
        <Form isUpdate data={data} onChange={onFormChange} />
      </Section>
      <AssignDialog
        title={i18n`Assign units`}
        open={assignDialog}
        data={stores.units.list}
        selected={selectedUnits}
        onAction={onAssignAction}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete resident`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this resident?`}</p>
      </DeleteDialog>
    </Fragment>
  )
}

export default view(ResidentDetail)
