import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/route'
import SignIn from 'scenes/signin'
import SignUp from 'scenes/signup'
import SignOut from 'scenes/signout'
import Passes from 'scenes/passes'
import Dashboard from 'scenes/dashboard'
import Account from 'scenes/account'
import Members from 'scenes/members'
import Resources from 'scenes/resources'
import Communities from 'scenes/communities'
import Reservations from 'scenes/reservations'
import Notifications from 'scenes/notifications'
import Polls from 'scenes/polls'
import Documents from 'scenes/documents'
import Incidents from 'scenes/incidents'
import Help from 'scenes/help'
import Home from 'scenes/home'

export default ({ location, ...props }) => (
  <Switch location={location} {...props}>
    <Route exact path="/signin" component={SignIn} {...props} />
    <Route exact path="/signup" component={SignUp} {...props} />
    <Route isPrivate exact path="/signout" component={SignOut} />
    <Route isPrivate path="/account" component={Account} />
    <Route isPrivate path="/members/:domain/:sub/:id?" component={Members} />
    <Route isPrivate path="/resources/:domain/:sub/:id?" component={Resources} />
    <Route isPrivate path="/passes/:domain" component={Passes} />
    <Route isPrivate path="/reservations/:domain" component={Reservations} />
    <Route isPrivate path="/documents/:domain" component={Documents} />
    <Route isPrivate path="/notifications/:domain" component={Notifications} />
    <Route isPrivate path="/polls/:domain" component={Polls} />
    <Route isPrivate path="/incidents/:domain" component={Incidents} />
    <Route isPrivate path="/dashboard/:domain" component={Dashboard} />
    <Route isPrivate path="/communities" component={Communities} />
    <Route isPrivate exact path="/help" component={Help} />
    <Route isPrivate exact path="/" component={Home} />
  </Switch>
)
