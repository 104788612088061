import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import SaveIcon from '@material-ui/icons/Save'
import { withTheme } from 'hocs'
import i18n from 'lib/i18n'

const Section = React.memo(
  ({
    children,
    noBackButton,
    noActions,
    onBack,
    backButtonLabel,
    renderActions,

    data,
    title,
    classes,
    noAssign,
    noDelete,
    onAssign,
    onDelete,
    toolbarActions,

    onSubmit,
    onCancel,
    submitButtonIcon,
    submitButtonLabel,
    cancelButtonLabel,
    disableSubmitButton,
    disableCancelButton,
    disableAssignButton,
    noCancelButton,
    noSubmitButton
  }) => {
    const renderToolbar = () => {
      return (
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            {typeof title === 'string' ? <Typography variant="h4">{title}</Typography> : title}
          </div>
          <div className={classes.toolbarActions}>
            {toolbarActions && toolbarActions()}
            {!toolbarActions && (
              <Fragment>
                {!noAssign && (
                  <Tooltip title={i18n`Assign`}>
                    <IconButton
                      aria-label={i18n`Assign`}
                      disabled={disableAssignButton}
                      onClick={() => onAssign(data.id)}
                    >
                      <PersonAddIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!noDelete && (
                  <Tooltip title={i18n`Delete`}>
                    <IconButton aria-label={i18n`Delete`} onClick={() => onDelete(data.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Fragment>
            )}
          </div>
        </Toolbar>
      )
    }

    const baseRenderActions = () => {
      return (
        <Fragment>
          {!noCancelButton && (
            <Button
              color="primary"
              className={classes.button}
              aria-label={i18n`Back`}
              onClick={onCancel}
              disabled={disableCancelButton}
            >
              {cancelButtonLabel || i18n`Cancel`}
            </Button>
          )}
          {!noSubmitButton && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              aria-label={i18n`Back`}
              onClick={onSubmit}
              disabled={disableSubmitButton}
            >
              {submitButtonIcon ? submitButtonIcon : <SaveIcon className={classes.buttonIcon} />}
              {submitButtonLabel || i18n`Submit`}
            </Button>
          )}
        </Fragment>
      )
    }

    return (
      <div className={classes.root}>
        {!noBackButton && (
          <Button color="primary" aria-label={i18n`Back`} onClick={onBack}>
            <ArrowBackIcon />
            {backButtonLabel || i18n`Back`}
          </Button>
        )}
        {renderToolbar()}
        <div className={classes.content}>{children}</div>
        {!noActions && (
          <div className={classes.actions}>
            {renderActions ? renderActions() : baseRenderActions()}
          </div>
        )}
      </div>
    )
  }
)

Section.defaultProps = {
  title: 'My title here',
  noAssign: false,
  noDelete: false,
  onAssign: () => {},
  onDelete: () => {},
  placeHolder: () => null,
  renderActions: null,
  disableAssignButton: false
}

export default withTheme('section')(Section)
