import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import bus from 'lib/bus'
import * as storage from 'lib/storage'
import * as cons from './constants'
import * as api from './api'

const state = { list: [], community: {} }
const store = createStore(state, 'communities')
const merge = createMerge(store)
const list = createList(store, 'list')
const isEmpty = obj => (obj ? !Object.keys(obj).length : true)

const get = id => {
  return list.get(id)
}

const setCommunity = community => {
  if (!community) return
  merge({ community })
  bus.emit('community::change', community)
}

const unsetCommunity = () => {
  merge({ community: {} })
}

const getCommunity = () => {
  const { community } = storage.get('community', {})
  if (!isEmpty(community)) return community
  if (!isEmpty(store.community)) return store.community
  if (store.list.length) return store.list[0]
  return {}
}

const setDefaultCommunity = () => {
  setCommunity(getCommunity())
}

const getDomain = () => {
  return getCommunity().domain
}

const fetch = async force => {
  const res = (await api.fetch()) || []

  list.replace(res)

  if (res.length === 0) {
    await unsetCommunity()
  } else {
    const community = res.find(item => item.domain === store.domain)
    if (community) await setCommunity(community)
    else await setDefaultCommunity()
  }

  return res
}

const create = async data => {
  const res = await api.create(data)
  return list.add(res)
}

const update = async data => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const destroy = async data => {
  const { id, domain } = await api.destroy(data)
  const community = getCommunity()
  const res = list.remove({ id })
  if (community.domain === domain) {
    unsetCommunity()
    setDefaultCommunity()
  }
  return res
}

const event = async ({ type, payload }) => {
  try {
    switch (type) {
      case cons.COMMUNITY_CREATED:
      case cons.COMMUNITY_UPDATED:
        return list.add(payload)
      case cons.COMMUNITY_DELETED:
        return list.remove(payload)
      case cons.UNIT_ASSIGNED:
      case cons.UNIT_UNASSIGNED:
      case cons.RESIDENT_ASSIGNED:
      case cons.RESIDENT_UNASSIGNED:
        return await fetch(true)
      default:
        break
    }
  } catch (error) {
    console.log(error)
  }
}

export default merge({
  get,
  fetch,
  create,
  update,
  destroy,
  getCommunity,
  setCommunity,
  getDomain,
  event,
  setDefaultCommunity
})
