import { get, post, put, del } from 'lib/api'

const toId = (id) => (id ? `/${id}` : '')

export const fetch = (domain, { id, ...data } = {}) => get(`/${domain}/residents${toId(id)}`, data)
export const create = (domain, data) => post(`/${domain}/residents`, data)

export const upload = (domain, { file }) => {
  const data = new FormData()
  data.append('file', file)
  return post(`/${domain}/residents/import`, data, { isFile: true })
}

export const invite = (domain, data) => post(`/${domain}/residents/invite`, data)
export const assign = (domain, data) => post(`/${domain}/residents/assign`, data)
export const download = (domain, data) => get(`/${domain}/residents/export`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/residents/${id}`, data)
export const destroy = (domain, data) => del(`/${domain}/residents`, data)
export const unassign = (domain, data) => del(`/${domain}/residents/unassign`, data)
