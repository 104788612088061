import React from 'react'
import ResidentDetail from './detail'
import ResidentCreate from './create'
import ResidentList from './list'

export default (props) => {
  const { id } = props.match.params
  if (id && id === 'create') return <ResidentCreate {...props} />
  else if (id && id !== 'create') return <ResidentDetail {...props} />
  else return <ResidentList {...props} />
}
