import slugify from 'slugify'

export const isSlug = (text) => {
  return /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(text)
}

export default (text) => slugify(text, {
  replacement: '-',
  remove: null,
  lower: true
})