import { createStore } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = {
  user: { id: '', name: '', email: '', phone: '' }
}

const store = createStore(state, 'account')
const merge = createMerge(store)

const fetch = async token => {
  const res = await api.fetch(token)
  store.user = { ...store.user, ...res }
  return res
}

const create = async data => {
  return api.create(data)
}

const update = async (data, token) => {
  const res = await api.update(data, token)
  store.user = { ...store.user, ...res }
  return res
}

const event = async ({ type }) => {
  try {
    switch (type) {
      case cons.ACCOUNT_SIGNED_IN:
        return await fetch()
      default:
        break
    }
  } catch (error) {
    console.log(error)
  }
}

export default merge({
  event,
  fetch,
  create,
  update
})
