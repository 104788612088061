import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 496 496" {...props}>
  	<g fill="none">
  		<g>
  			<path d="m144 224h224v-168h-224c-8.84 0-16 7.16-16 16v136c0 8.84 7.16 16 16 16z" fill="#2196F3"/>
  			<path d="m240 0v56h16v-40h224v464h-224v-104h38.776c12.64 28.928 41.368 48 73.224 48 44.112 0 80-35.888 80-80s-35.888-80-80-80c-31.856 0-60.592 19.072-73.224 48h-38.776v-88h-16v88h-240v64h240v120h256v-496h-256zm-184 360v-32h320c8.824 0 16 7.176 16 16s-7.176 16-16 16h-320zm312-80c35.288 0 64 28.712 64 64s-28.712 64-64 64c-23.024 0-43.92-12.52-55.216-32h63.216c17.648 0 32-14.352 32-32s-14.352-32-32-32h-63.216c11.296-19.48 32.192-32 55.216-32zm-352 48h24v32h-24v-32z" fill="#666"/>
  			<polygon fill="#666" points="360 248 376 248 376 32 360 32 360 88 128 88 128 104 360 104"/>
  			<rect height="16" width="160" fill="#666" x="272" y="448"/>
  			<rect height="16" width="16" fill="#666" x="448" y="448"/>
  			<path d="m336 192v-40h-113.14c-3.576-13.768-15.992-24-30.864-24-17.648 0-32 14.352-32 32s14.352 32 32 32c14.872 0 27.288-10.232 30.864-24h65.136v24h16v-24h16v24h16zm-144-16c-8.824 0-16-7.176-16-16s7.176-16 16-16 16 7.176 16 16-7.176 16-16 16z" fill="#666"/>
  		</g>
  	</g>
  </svg>
)
