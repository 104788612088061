import React, { useEffect, useReducer, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import toast from 'lib/toast'
import fileDownload from 'react-file-download'
import {
  PlaceHolder,
  Button,
  Table,
  OwnerImg,
  DeleteDialog,
  AssignDialog,
  UploadDialog
} from 'components'
import { SEARCH_KEYS } from 'data/resident/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const ResidentList = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    uploadDialog: false,
    assignDialog: false,
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { list } = stores.residents
  const { domain } = match.params
  const { keyword, guide, assignDialog, uploadDialog, deleteDialog } = state
  const data = keyword ? search(normalize(list), keyword) : list

  useEffect(() => {
    if (domain) fetch(domain)
  }, [domain])

  const columns = [
    { id: 'name', label: i18n`Name` },
    { id: 'email', label: i18n`Email` },
    { id: 'units', label: i18n`Units` }
  ]

  const selected = state.selected.map(id => ({ id }))

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    const promises = [stores.residents.fetch(domain), stores.units.fetch(domain)]

    try {
      await Promise.all(promises)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching residents information`)
    }
  }

  const update = async data => {
    try {
      await stores.residents.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update resident`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.residents.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.units.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete resident(s)`)
    }
    reset()
  }

  const assign = async (residents, units) => {
    try {
      const data = residents.reduce((acc, resident) => {
        const ids = units.map(unit => ({ unitId: unit.id, residentId: resident.id }))
        acc.push(...ids)
        return acc
      }, [])
      await stores.residents.assign(domain, data)
      toast.success(i18n`Assigned`)
      stores.units.fetch(domain, true)
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to assign resident(s)`)
    }
    reset()
  }

  const unassign = async data => {
    try {
      await stores.residents.unassign(domain, data)
      toast.success(i18n`Unassigned`)
      stores.units.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to unassign resident`)
    }
  }

  const upload = async data => {
    try {
      await stores.residents.upload(domain, data)
      toast.success(i18n`Data imported`)
      stores.units.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to import data. Check your data and try again`)
    }
    reset()
  }

  const download = async data => {
    try {
      const file = await stores.residents.download(domain, data)
      fileDownload(file, 'residents.csv')
    } catch (error) {
      toast.error(i18n`Unable to download residents`)
    }
    reset()
  }

  const normalize = data => {
    return data.map(resident => {
      const unitsByTitle = resident.units.map(unit => unit.title)
      return { ...resident, unitsByTitle }
    })
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onUploadAction = ({ ok, data }) => {
    if (ok) upload(data)
    closeUploadDialog()
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onAssignAction = ({ ok, ...rest }) => {
    if (ok && rest.assign.length) assign(selected, rest.assign)
    if (ok && rest.unassign.length) unassign(selected, rest.unassign)
    if (!ok) reset()
    closeAssignDialog()
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onUpdate = data => {
    update(data)
  }

  const onUnassign = (data = []) => {
    const [residentId, unitId] = data
    unassign({ residentId, unitId })
  }

  const onDownload = () => {
    download(selected)
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const openUploadDialog = () => {
    setState({ uploadDialog: true })
  }

  const closeUploadDialog = () => {
    setState({ uploadDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openAssignDialog = () => {
    setState({ assignDialog: true })
  }

  const closeAssignDialog = () => {
    setState({ assignDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <OwnerImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE RESIDENT`}</Button>
            <Button onClick={openUploadDialog}>{i18n`IMPORT RESIDENTS`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5">
          {i18n`You haven't added any residents to your community yet`}
        </Typography>
      </PlaceHolder>
    )
  }

  return (
    <Fragment>
      <Table
        noInvite
        noItemDelete
        data={data}
        selected={state.selected}
        columns={columns}
        className="residents"
        title={i18n`Residents`}
        onRow={page}
        onDetail={page}
        onCreate={() => page('create')}
        onSearch={onSearch}
        onGuide={openGuide}
        onSwitch={onUpdate}
        onSelected={onSelected}
        onDownload={onDownload}
        onChipDelete={onUnassign}
        onUpload={openUploadDialog}
        onDelete={openDeleteDialog}
        onAssign={openAssignDialog}
        placeHolder={renderPlaceHolder}
      />
      <UploadDialog title={i18n`Import residents`} open={uploadDialog} onAction={onUploadAction} />
      <AssignDialog
        title={i18n`Assign units`}
        open={assignDialog}
        data={stores.units.list}
        onAction={onAssignAction}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete residents`} onAction={onDeleteAction}>
        <p>
          {state.selected.length > 1
            ? i18n`Are you sure you want to delete the selected residents?`
            : i18n`Are you sure you want to delete this resident?`}
        </p>
      </DeleteDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

// class ResidentList extends Component {
//   state = {
//     uploadDialog: false,
//     deleteDialog: false,
//     assignDialog: false,
//     guide: false,
//     selected: [],
//     keyword: ''
//   }

//   search = createSearch(SEARCH_KEYS)

//   get domain() {
//     return this.props.match.params.domain
//   }

//   get data() {
//     const list = stores.residents.list
//     const { keyword } = this.state
//     return keyword ? this.search(this.normalize(list), keyword) : list
//   }

//   get columns() {
//     return [
//       { id: 'name', label: i18n`Name` },
//       { id: 'email', label: i18n`Email` },
//       { id: 'units', label: i18n`Units` }
//     ]
//   }

//   get selected() {
//     return this.state.selected.map(id => ({ id }))
//   }

//   componentDidMount() {
//     this.fetch()
//   }

//   componentDidUpdate(prevProps) {
//     if (this.domain !== prevProps.match.params.domain) {
//       this.fetch()
//     }
//   }

//   reset = () => {
//     this.setState({ selected: [] })
//   }

//   fetch = async () => {
//     const promises = [stores.residents.fetch(this.domain), stores.units.fetch(this.domain)]

//     try {
//       await Promise.all(promises)
//     } catch (error) {
//       toast.error(i18n`Unable to complete fetching residents information`)
//     }
//   }

//   update = async data => {
//     try {
//       await stores.residents.update(this.domain, data)
//       toast.success(i18n`Updated`)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to update resident`)
//     }
//     this.reset()
//   }

//   destroy = async data => {
//     try {
//       await stores.residents.destroy(this.domain, data)
//       toast.success(i18n`Deleted`)
//       stores.units.fetch(this.domain, true)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to delete resident(s)`)
//     }
//     this.reset()
//   }

//   assign = async (residents, units) => {
//     try {
//       const data = residents.reduce((acc, resident) => {
//         const ids = units.map(unit => ({ unitId: unit.id, residentId: resident.id }))
//         acc.push(...ids)
//         return acc
//       }, [])
//       await stores.residents.assign(this.domain, data)
//       toast.success(i18n`Assigned`)
//       stores.units.fetch(this.domain, true)
//     } catch (error) {
//       console.log(error)
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to assign resident(s)`)
//     }
//     this.reset()
//   }

//   unassign = async data => {
//     try {
//       await stores.residents.unassign(this.domain, data)
//       toast.success(i18n`Unassigned`)
//       stores.units.fetch(this.domain, true)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to unassign resident`)
//     }
//   }

//   upload = async data => {
//     try {
//       await stores.residents.upload(this.domain, data)
//       toast.success(i18n`Data imported`)
//       stores.units.fetch(this.domain, true)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to import data. Check your data and try again`)
//     }
//     this.reset()
//   }

//   download = async data => {
//     try {
//       const file = await stores.residents.download(this.domain, data)
//       fileDownload(file, 'residents.csv')
//     } catch (error) {
//       toast.error(i18n`Unable to download residents`)
//     }
//     this.reset()
//   }

//   normalize(data) {
//     return data.map(resident => {
//       const unitsByTitle = resident.units.map(unit => unit.title)
//       return { ...resident, unitsByTitle }
//     })
//   }

//   page = id => {
//     this.props.history.push(`${this.props.match.url}/${id}`)
//   }

//   onUploadAction = ({ ok, data }) => {
//     if (ok) this.upload(data)
//     this.closeUploadDialog()
//   }

//   onDeleteAction = ({ ok }) => {
//     if (ok) this.destroy(this.selected)
//     else this.reset()
//     this.closeDeleteDialog()
//   }

//   onAssignAction = ({ ok, assign, unassign }) => {
//     if (ok && assign.length) this.assign(this.selected, assign)
//     if (ok && unassign.length) this.unassign(this.selected, unassign)
//     if (!ok) this.reset()
//     this.closeAssignDialog()
//   }

//   onSelected = selected => {
//     this.setState({ selected })
//   }

//   onUpdate = data => {
//     this.update(data)
//   }

//   onUnassign = (data = []) => {
//     const [residentId, unitId] = data
//     this.unassign({ residentId, unitId })
//   }

//   onDownload = () => {
//     this.download(this.selected)
//   }

//   onSearch = keyword => {
//     this.setState({ keyword })
//   }

//   openUploadDialog = () => {
//     this.setState({ uploadDialog: true })
//   }

//   closeUploadDialog = () => {
//     this.setState({ uploadDialog: false })
//   }

//   openDeleteDialog = () => {
//     this.setState({ deleteDialog: true })
//   }

//   closeDeleteDialog = () => {
//     this.setState({ deleteDialog: false })
//   }

//   openAssignDialog = () => {
//     this.setState({ assignDialog: true })
//   }

//   closeAssignDialog = () => {
//     this.setState({ assignDialog: false })
//   }

//   openGuide = () => {
//     this.setState({ guide: true })
//   }

//   closeGuide = () => {
//     this.setState({ guide: false })
//   }

//   renderPlaceHolder = () => {
//     return (
//       <PlaceHolder
//         media={() => <OwnerImg width="200" height="200" />}
//         actions={() => (
//           <Fragment>
//             <Button onClick={() => this.page('create')}>{i18n`CREATE RESIDENT`}</Button>
//             <Button onClick={this.openUploadDialog}>{i18n`IMPORT RESIDENTS`}</Button>
//           </Fragment>
//         )}
//       >
//         <Typography variant="h5">
//           {i18n`You haven't added any residents to your community yet`}
//         </Typography>
//       </PlaceHolder>
//     )
//   }

//   render() {
//     const { guide, selected, assignDialog, uploadDialog, deleteDialog } = this.state
//     return (
//       <Fragment>
//         <Table
//           {...this.props}
//           noInvite
//           noItemDelete
//           data={this.data}
//           selected={selected}
//           columns={this.columns}
//           className="residents"
//           title={i18n`Residents`}
//           onRow={this.page}
//           onDetail={this.page}
//           onCreate={() => this.page('create')}
//           onSearch={this.onSearch}
//           onGuide={this.openGuide}
//           onSwitch={this.onUpdate}
//           onSelected={this.onSelected}
//           onDownload={this.onDownload}
//           onChipDelete={this.onUnassign}
//           onUpload={this.openUploadDialog}
//           onDelete={this.openDeleteDialog}
//           onAssign={this.openAssignDialog}
//           placeHolder={this.renderPlaceHolder}
//         />
//         <UploadDialog
//           title={i18n`Upload residents`}
//           open={uploadDialog}
//           onAction={this.onUploadAction}
//         />
//         <AssignDialog
//           title={i18n`Assign units`}
//           open={assignDialog}
//           data={stores.units.list}
//           onAction={this.onAssignAction}
//         />
//         <DeleteDialog
//           open={deleteDialog}
//           title={i18n`Delete residents`}
//           onAction={this.onDeleteAction}
//         >
//           <p>
//             {selected.length > 1
//               ? i18n`Are you sure you want to delete the selected residents?`
//               : i18n`Are you sure you want to delete this resident?`}
//           </p>
//         </DeleteDialog>
//         <Guide open={guide} onClose={this.closeGuide} />
//       </Fragment>
//     )
//   }
// }

export default view(ResidentList)
