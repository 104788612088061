import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 291 226" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M257.813,211.75 C257.813,214.337 255.713,216.438 253.125,216.438 L14.063,216.438 C11.475,216.438 9.375,214.338 9.375,211.75 L9.375,75.813 L257.813,75.813 L257.813,136.75 L267.188,136.75 L267.188,43 C267.188,35.247 260.879,28.937 253.125,28.937 L234.375,28.937 L234.375,14.875 C234.375,7.122 228.066,0.812 220.312,0.812 C212.559,0.812 206.249,7.121 206.249,14.875 L206.249,28.938 L60.938,28.938 L60.938,14.875 C60.938,7.122 54.629,0.812 46.875,0.812 C39.121,0.812 32.812,7.121 32.812,14.875 L32.812,28.938 L14.062,28.938 C6.309,28.938 0,35.247 0,43 L0,211.75 C0,219.503 6.309,225.813 14.063,225.813 L253.126,225.813 C260.879,225.813 267.189,219.504 267.189,211.75 L267.189,202.375 L257.814,202.375 L257.814,211.75 L257.813,211.75 Z M215.625,14.875 C215.625,12.292 217.725,10.187 220.313,10.187 C222.901,10.187 225,12.292 225,14.875 L225,33.625 C225,36.208 222.9,38.313 220.312,38.313 C217.724,38.313 215.624,36.208 215.624,33.625 L215.624,14.875 L215.625,14.875 Z M42.188,14.875 C42.188,12.292 44.288,10.187 46.876,10.187 C49.464,10.187 51.564,12.292 51.564,14.875 L51.564,33.625 C51.564,36.208 49.464,38.313 46.876,38.313 C44.288,38.313 42.188,36.208 42.188,33.625 L42.188,14.875 Z M14.063,38.313 L33.676,38.313 C35.617,43.755 40.773,47.688 46.876,47.688 C52.979,47.688 58.135,43.755 60.076,38.313 L207.118,38.313 C209.059,43.755 214.215,47.688 220.318,47.688 C226.421,47.688 231.577,43.755 233.518,38.313 L253.126,38.313 C255.713,38.313 257.814,40.418 257.814,43.001 L257.814,66.44 L9.375,66.44 L9.375,43 C9.375,40.417 11.475,38.313 14.063,38.313 Z" fill="#666666"></path>
        <path d="M250.782,207.063 C228.812,207.063 210.938,189.19 210.938,167.219 C210.938,145.248 228.811,127.375 250.782,127.375 C272.753,127.375 290.626,145.248 290.626,167.219 C290.626,189.19 272.752,207.063 250.782,207.063 Z" fill="#2196F3"></path>
        <polygon fill="#666666" points="232.289 165.663 227.086 173.463 244.688 185.196 289.566 130.342 282.31 124.408 242.813 172.68"></polygon>
        <path d="M56.25,89.875 L23.438,89.875 L23.438,118 L56.25,118 L56.25,89.875 Z M46.875,108.625 L32.813,108.625 L32.813,99.25 L46.876,99.25 L46.875,108.625 Z" fill="#666666"></path>
        <path d="M103.125,89.875 L70.313,89.875 L70.313,118 L103.126,118 L103.126,89.875 L103.125,89.875 Z M93.75,108.625 L79.688,108.625 L79.688,99.25 L93.75,99.25 L93.75,108.625 Z" fill="#666666"></path>
        <path d="M150,89.875 L117.187,89.875 L117.187,118 L150,118 L150,89.875 Z M140.625,108.625 L126.562,108.625 L126.562,99.25 L140.625,99.25 L140.625,108.625 Z" fill="#666666"></path>
        <path d="M196.875,89.875 L164.062,89.875 L164.062,118 L196.875,118 L196.875,89.875 Z M187.5,108.625 L173.437,108.625 L173.437,99.25 L187.5,99.25 L187.5,108.625 Z" fill="#666666"></path>
        <path d="M243.75,89.875 L210.937,89.875 L210.937,118 L243.75,118 L243.75,89.875 Z M234.375,108.625 L220.312,108.625 L220.312,99.25 L234.375,99.25 L234.375,108.625 Z" fill="#666666"></path>
        <path d="M56.25,132.063 L23.438,132.063 L23.438,160.188 L56.25,160.188 L56.25,132.063 Z M46.875,150.813 L32.813,150.813 L32.813,141.438 L46.876,141.438 L46.875,150.813 Z" fill="#666666"></path>
        <path d="M103.125,132.063 L70.313,132.063 L70.313,160.188 L103.126,160.188 L103.126,132.063 L103.125,132.063 Z M93.75,150.813 L79.688,150.813 L79.688,141.438 L93.75,141.438 L93.75,150.813 Z" fill="#666666"></path>
        <path d="M150,132.063 L117.187,132.063 L117.187,160.188 L150,160.188 L150,132.063 Z M140.625,150.813 L126.562,150.813 L126.562,141.438 L140.625,141.438 L140.625,150.813 Z" fill="#666666"></path>
        <path d="M196.875,132.063 L164.062,132.063 L164.062,160.188 L196.875,160.188 L196.875,132.063 Z M187.5,150.813 L173.437,150.813 L173.437,141.438 L187.5,141.438 L187.5,150.813 Z" fill="#666666"></path>
        <path d="M23.438,202.375 L56.25,202.375 L56.25,174.25 L23.438,174.25 L23.438,202.375 Z M32.813,183.625 L46.876,183.625 L46.876,193 L32.813,193 L32.813,183.625 Z" fill="#666666"></path>
        <path d="M70.313,202.375 L103.126,202.375 L103.126,174.25 L70.313,174.25 L70.313,202.375 Z M79.688,183.625 L93.75,183.625 L93.75,193 L79.688,193 L79.688,183.625 Z" fill="#666666"></path>
        <path d="M117.188,202.375 L150,202.375 L150,174.25 L117.187,174.25 L117.187,202.375 L117.188,202.375 Z M126.563,183.625 L140.626,183.625 L140.626,193 L126.563,193 L126.563,183.625 Z" fill="#666666"></path>
        <path d="M164.063,202.375 L196.876,202.375 L196.876,174.25 L164.063,174.25 L164.063,202.375 Z M173.438,183.625 L187.5,183.625 L187.5,193 L173.437,193 L173.437,183.625 L173.438,183.625 Z" fill="#666666"></path>
      </g>
    </g>
  </svg>
)
