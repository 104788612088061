import React, { useReducer, useEffect, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import fileDownload from 'react-file-download'
import {
  PlaceHolder,
  Button,
  Table,
  HouseTilesImg,
  DeleteDialog,
  AssignDialog,
  UploadDialog
} from 'components'
import { SEARCH_KEYS } from 'data/unit/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const UnitList = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    uploadDialog: false,
    assignDialog: false,
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { list } = stores.units
  const { domain } = match.params
  const { keyword, guide, assignDialog, uploadDialog, deleteDialog } = state
  const data = keyword ? search(normalize(list), keyword) : list

  useEffect(() => {
    if (domain) fetch(domain)
  }, [domain])

  const columns = [
    { id: 'code', label: i18n`Code` },
    { id: 'title', label: i18n`Title` },
    { id: 'residents', label: i18n`Residents` }
  ]

  const selected = state.selected.map(id => ({ id }))

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    try {
      const promises = [stores.residents.fetch(domain), stores.units.fetch(domain)]
      await Promise.all(promises)
    } catch (error) {
      toast.error(i18n`Unable to fetch units`)
    }
  }

  const update = async data => {
    try {
      await stores.units.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update unit`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.units.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.residents.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete unit(s)`)
    }
    reset()
  }

  const assign = async (units, residents) => {
    try {
      const data = units.reduce((acc, unit) => {
        const ids = residents.map(resident => ({ residentId: resident.id, unitId: unit.id }))
        acc.push(...ids)
        return acc
      }, [])

      await stores.units.assign(domain, data)
      toast.success(i18n`Assigned`)
      stores.residents.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to assign resident(s)`)
    }
    reset()
  }

  const unassign = async data => {
    try {
      await stores.units.unassign(domain, data)
      toast.success(i18n`Unassigned`)
      stores.residents.fetch(domain, true)
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to unassign resident`)
    }
  }

  const upload = async data => {
    try {
      await stores.units.upload(domain, data)
      toast.success(i18n`Data imported`)
      stores.residents.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to import data. Check your data and try again`)
    }
    reset()
  }

  const download = async data => {
    try {
      const file = await stores.units.download(domain, data)
      fileDownload(file, 'units.csv')
    } catch (error) {
      toast.error(i18n`Unable to download units`)
    }
    reset()
  }

  const normalize = units => {
    return units.map(unit => {
      const residentsByName = unit.residents.map(resident => resident.name)
      return { ...unit, residentsByName }
    })
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const onUpdate = data => {
    update(data)
  }

  const onUnassign = (data = []) => {
    const [unitId, residentId] = data
    unassign({ unitId, residentId })
  }

  const onDownload = () => {
    download(selected)
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onUploadAction = ({ ok, data }) => {
    if (ok) upload(data)
    closeUploadDialog()
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onAssignAction = ({ ok, ...res }) => {
    if (ok && res.assign.length) assign(selected, res.assign)
    if (ok && res.unassign.length) unassign(selected, res.unassign)
    closeAssignDialog()
    reset()
  }

  const openUploadDialog = () => {
    setState({ uploadDialog: true })
  }

  const closeUploadDialog = () => {
    setState({ uploadDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openAssignDialog = () => {
    setState({ assignDialog: true })
  }

  const closeAssignDialog = () => {
    setState({ assignDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <HouseTilesImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE UNIT`}</Button>
            <Button onClick={openUploadDialog}>{i18n`IMPORT UNITS`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5" component="h2">
          {i18n`You haven't created any unit yet`}
        </Typography>
      </PlaceHolder>
    )
  }

  return (
    <Fragment>
      <Table
        noInvite
        noItemDelete
        data={data}
        selected={state.selected}
        className="units"
        title={i18n`Units`}
        columns={columns}
        onRow={page}
        onDetail={page}
        onSwitch={onUpdate}
        onSearch={onSearch}
        onGuide={openGuide}
        onSelected={onSelected}
        onDownload={onDownload}
        onChipDelete={onUnassign}
        onUpload={openUploadDialog}
        onAssign={openAssignDialog}
        onDelete={openDeleteDialog}
        onCreate={() => page('create')}
        placeHolder={renderPlaceHolder}
      />
      <UploadDialog title={i18n`Import units`} open={uploadDialog} onAction={onUploadAction} />
      <AssignDialog
        title={i18n`Assign residents`}
        open={assignDialog}
        data={stores.residents.list}
        onAction={onAssignAction}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete units`} onAction={onDeleteAction}>
        <p>
          {state.selected.length > 1
            ? i18n`Are you sure you want to delete the selected units?`
            : i18n`Are you sure you want to delete this unit?`}
        </p>
      </DeleteDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default view(UnitList)
