import React, { Fragment, useEffect, useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import { PlaceHolder, Button, Table, CommunityImg, CreateDialog, DeleteDialog } from 'components'
import { SEARCH_KEYS } from 'data/community/constants'
import { stores } from 'data'
import useSync from 'hooks/use-sync'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import slug from 'lib/slug'
import bus from 'lib/bus'
import Form from './form'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const Communities = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    createDialog: false,
    deleteDialog: false,
    domainDirty: false,
    isValid: false,
    guide: false,
    keyword: '',
    errors: {},
    data: {
      title: '',
      domain: '',
      province: ''
    }
  })

  const syncing = useSync()
  const search = createSearch(SEARCH_KEYS)

  useEffect(() => {
    if (!syncing) fetch()
  }, [syncing])

  const { keyword, isValid, guide, createDialog, deleteDialog } = state
  const { list } = stores.communities
  const data = keyword ? search(list, keyword) : list

  const columns = [
    { id: 'title', label: i18n`Title` },
    { id: 'domain', label: i18n`Domain` },
    { id: 'province', label: i18n`Province` },
    { id: 'address', label: i18n`Address` }
  ]

  const reset = () => {
    setState({
      errors: {},
      isValid: false,
      domainDirty: false,
      data: { title: '', domain: '' }
    })
  }

  const fetch = async () => {
    try {
      await stores.communities.fetch()
    } catch (error) {
      toast.error(i18n`Unable to fetch communities`)
    }
  }

  const create = async data => {
    try {
      await stores.communities.create(data)
      toast.success(i18n`Created`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to create community`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.communities.destroy(data)
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete community(s)`)
    }
    reset()
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const onFormChange = (data, isValid, errors) => {
    let domainDirty = state.domainDirty

    if (data.domain !== state.data.domain) {
      domainDirty = true
    }

    data.domain = domainDirty ? data.domain : slug(data.title)
    setState({ data, isValid, errors, domainDirty })
  }

  const onCreateAction = ({ ok }) => {
    if (ok) create(state.data)
    else reset()
    closeCreateDialog()
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(state.data)
    closeDeleteDialog()
  }

  const openCreateDialog = () => {
    setState({ createDialog: true })
  }

  const closeCreateDialog = () => {
    setState({ createDialog: false })
  }

  const openDeleteDialog = data => {
    setState({ deleteDialog: true, data })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <CommunityImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={openCreateDialog}>{i18n`CREATE COMMUNITY`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5" component="h2">
          {i18n`You haven't created any community yet`}
        </Typography>
      </PlaceHolder>
    )
  }

  return (
    <Fragment>
      <Table
        noInvite
        noAssign
        noUpload
        noDownload
        noCheckbox
        noItemSwitch
        noSelectedDownload
        data={data}
        columns={columns}
        className="communities"
        title={i18n`Communities`}
        onRow={page}
        onDetail={page}
        onSearch={onSearch}
        onGuide={openGuide}
        onCreate={openCreateDialog}
        onDelete={openDeleteDialog}
        placeHolder={renderPlaceHolder}
      />
      <CreateDialog
        title={i18n`Create community`}
        open={createDialog}
        onAction={onCreateAction}
        okDisabled={!isValid}
      >
        <Form {...state} onChange={onFormChange} />
      </CreateDialog>
      <DeleteDialog title={i18n`Delete community`} open={deleteDialog} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this community?`}</p>
      </DeleteDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default view(Communities)
