import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'reservations')
const merge = createMerge(store)
const list = createList(store, 'list')

const get = id => {
  return list.get(id)
}

const fetch = async (domain, force) => {
  const res = (await api.fetch(domain)) || []
  return list.replace(res)
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const update = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const approve = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.approve(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const cancel = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.cancel(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const destroy = async (domain, data) => {
  const res = await api.destroy(domain, data)
  return list.remove(res)
}

const download = async (domain, data = []) => {
  let query = {}

  if (Array.isArray(data)) {
    query.ids = data.map(item => item.id).join(',')
  } else {
    query = data
  }

  return api.download(domain, query)
}

const event = ({ type, payload }) => {
  switch (type) {
    case cons.RESERVATION_CREATED:
    case cons.RESERVATION_UPDATED:
    case cons.RESERVATION_APPROVED:
    case cons.RESERVATION_CANCELLED:
      return list.add(payload)
    case cons.RESERVATION_DELETED:
      return list.remove(payload)
    default:
      break
  }
}

export default merge({
  get,
  fetch,
  create,
  update,
  approve,
  cancel,
  destroy,
  download,
  event
})
