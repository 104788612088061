import React, { Fragment, useEffect, useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import TimerIcon from '@material-ui/icons/Timer'
import TimerOffIcon from '@material-ui/icons/TimerOff'
import fileDownload from 'react-file-download'
import { PlaceHolder, Button, Table, KeysImg, OptionDialog, PeriodDialog } from 'components'
import { SEARCH_KEYS } from 'data/pass/constants'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import { mapStatus, mapType } from 'data/pass/maps'
import { timeAgo } from 'lib/utils'
import createSearch from 'lib/search'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const PassList = ({ classes, match, history }) => {
  const [state, setState] = useReducer(reducer, {
    downloadDialog: false,
    optionDialog: false,
    guide: false,
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { domain } = match.params
  const { keyword, guide, optionDialog, downloadDialog } = state
  const list = stores.passes.list
  const data = keyword ? search(list, keyword) : list

  const columns = [
    { id: 'guest.name', label: i18n`Guest` },
    { id: 'owner.name', label: i18n`Sender` },
    { id: 'place.title', label: i18n`Visiting` },
    { id: 'type', label: i18n`Pass type` },
    { id: 'updated', label: i18n`Last activity` },
    { id: 'status', label: i18n`Status` }
  ]

  const options = [
    { id: 'temporal', label: i18n`Temporal pass`, icon: <TimerIcon /> },
    { id: 'permanent', label: i18n`Permanent pass`, icon: <TimerOffIcon /> }
  ]

  const downloadPeriods = [
    {
      label: i18n`Current month`,
      value: '0'
    },
    {
      label: i18n`Last month`,
      value: '-1'
    },
    {
      label: i18n`Last two months`,
      value: '-2'
    },
    {
      label: i18n`Last four months`,
      value: '-4'
    },
    {
      label: i18n`Last six months`,
      value: '-6'
    },
    {
      label: i18n`Last twelve months`,
      value: '-12'
    }
  ]

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  useEffect(() => {
    const timer = setInterval(tick, 30000)
    return () => clearInterval(timer)
  }, [])

  const tick = () => {
    setState({ seconds: state.seconds + 1 })
  }

  const fetch = async () => {
    try {
      await stores.passes.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to fetch passes`)
    }
  }

  const download = async period => {
    try {
      const file = await stores.passes.download(domain, { period })
      fileDownload(file, 'passes.csv')
    } catch (error) {
      toast.error(i18n`Unable to download passes`)
    }
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const onDownloadAction = ({ ok, period }) => {
    if (ok) download(period)
    closeDownloadDialog()
  }

  const onOptionAction = ({ id: type, ok }) => {
    if (ok) page(`create/${type}`)
    closeOptionDialog()
  }

  const openOptionDialog = () => {
    setState({ optionDialog: true })
  }

  const closeDownloadDialog = () => {
    setState({ downloadDialog: false })
  }

  const openDownloadDialog = () => {
    setState({ downloadDialog: true })
  }

  const closeOptionDialog = () => {
    setState({ optionDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <KeysImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={openOptionDialog}>{i18n`CREATE A PASS`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5" component="h2">
          {i18n`No passes to show at this moment`}
        </Typography>
      </PlaceHolder>
    )
  }

  const renderRow = (node, key, data) => {
    if (key === 'status') {
      node = (
        <Typography className={classes[`STATUS_${data.status}`]}>
          {mapStatus(data.status)}
        </Typography>
      )
    }

    if (key === 'guest.name') {
      node = data.guest.name
    } else if (key === 'owner.name') {
      node = data.owner.name
    } else if (key === 'place.title') {
      node = data.place.title
    } else if (key === 'updated') {
      node = timeAgo(data.updated)
    }

    if (key === 'type') {
      node = mapType(data.type)
    }

    // if (key === 'guest.name') {
    //   return (
    //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //       <Avatar style={{ borderRadius: '25%' }} className={classes.avatar} />
    //       <div style={{ marginLeft: 16 }}>{node}</div>
    //     </div>
    //   )
    // }

    return node
  }

  console.log(data)

  return (
    <Fragment>
      <Table
        noInvite
        noAssign
        noUpload
        noCheckbox
        noItemSwitch
        noItemDelete
        data={data}
        className="passes"
        title={i18n`Passes`}
        columns={columns}
        onRow={page}
        onDetail={page}
        onSearch={onSearch}
        onGuide={openGuide}
        onDownload={openDownloadDialog}
        placeHolder={renderPlaceHolder}
        onCreate={openOptionDialog}
        row={renderRow}
      />
      <OptionDialog
        open={optionDialog}
        options={options}
        onAction={onOptionAction}
        onClose={closeOptionDialog}
        title={i18n`Choose pass type`}
      />
      <PeriodDialog
        open={downloadDialog}
        periods={downloadPeriods}
        onAction={onDownloadAction}
        onClose={closeDownloadDialog}
        title={i18n`Download period`}
        okLabel={i18n`Download`}
      />
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default compose(
  withTheme('passes'),
  view
)(PassList)
