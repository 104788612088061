import React, { Fragment, useReducer, useEffect } from 'react'
import { Section, AssignDialog, DeleteDialog } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { pick } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const UnitDetail = ({ match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    assignDialog: false,
    deleteDialog: false
  })

  const { id, domain } = match.params
  const { isValid, deleteDialog, assignDialog } = state

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const unit = stores.units.get(id)

  const isSameData =
    unit &&
    unit.code === state.data.code &&
    unit.title === state.data.title &&
    unit.active === state.data.active

  const reset = () => {
    setState({ data: {}, errors: {}, isValid: false })
  }

  const fetch = async () => {
    try {
      await stores.units.fetch(domain, { id: id })
    } catch (err) {
      toast.error(i18n`Unable to fetch unit information`)
      back()
    }
  }

  const update = async data => {
    try {
      await stores.units.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update unit`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.units.destroy(domain, data)
      back()
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete unit`)
      }
    }
  }

  const assign = async residents => {
    try {
      const data = residents.map(resident => ({ unitId: unit.id, residentId: resident.id }))
      await stores.units.assign(domain, data)
      toast.success(i18n`Assigned`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to assign unit`)
    }
    reset()
  }

  const unassign = async residents => {
    try {
      const data = residents.map(resident => ({ unitId: unit.id, residentId: resident.id }))
      await stores.units.unassign(domain, data)
      toast.success(i18n`Unassigned`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to unassign unit`)
    }
    reset()
  }

  const back = () => {
    history.push(`/resources/${domain}/units`)
    return null
  }

  const onFormChange = (data, isValid, errors) => {
    data = pick(data, ['id', 'code', 'title', 'active'])
    setState({ data, isValid, errors })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id: id })
    closeDeleteDialog()
  }

  const onAssignAction = ({ ok, ...rest }) => {
    if (ok && rest.assign.length) assign(rest.assign)
    if (ok && rest.unassign.length) unassign(rest.unassign)
    closeAssignDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openAssignDialog = () => {
    setState({ assignDialog: true })
  }

  const closeAssignDialog = () => {
    setState({ assignDialog: false })
  }

  if (!unit) return back()

  const selectedResidents = unit.residents.map(resident => resident.id)

  const data = {
    ...unit,
    ...state.data,
    residents: unit.residents.map(resident => ` ${resident.name}`).sort()
  }

  return (
    <Fragment>
      <Section
        {...props}
        data={data}
        title={i18n`Unit Detail`}
        backButtonLabel={i18n`Units`}
        onBack={back}
        onCancel={back}
        onSubmit={onUpdate}
        onAssign={openAssignDialog}
        onDelete={openDeleteDialog}
        disableSubmitButton={!isValid || isSameData}
        disableAssignButton={data.residents.length === 0}
      >
        <Form isUpdate data={data} onChange={onFormChange} />
      </Section>
      <AssignDialog
        title={i18n`Assign residents`}
        open={assignDialog}
        data={stores.residents.list}
        selected={selectedResidents}
        onAction={onAssignAction}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete unit`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this unit?`}</p>
      </DeleteDialog>
    </Fragment>
  )
}

export default view(UnitDetail)
