import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 396 396" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M197.818,150.82 C156.38,150.82 122.376,116.816 122.376,75.378 C122.376,33.94 156.38,0 197.818,0 C239.256,0 273.26,34.004 273.26,75.442 C273.26,116.88 239.257,150.82 197.818,150.82 Z" id="Shape" fill="#FDBF5E"></path>
        <g transform="translate(137.000000, 34.000000)" fill="#FFFFFF">
          <circle id="Oval" cx="60.818" cy="32.133" r="32.129"></circle>
          <path d="M97.925,64.909 L23.711,64.909 C10.717,64.909 0.244,75.446 0.244,88.376 L0.244,122.38 C16.923,135.374 37.933,142.808 60.818,142.808 C83.703,142.808 104.713,135.374 121.392,122.38 L121.392,88.376 C121.392,75.446 110.919,64.909 97.925,64.909 Z" id="Shape"></path>
        </g>
        <path d="M259.62,288.711 C260.266,288.065 260.848,288.065 261.495,288.065 L204.024,230.594 L204.024,182.368 C225.034,181.14 244.816,173.705 262.141,160.711 L264.598,158.836 L264.598,122.375 C264.598,105.696 251.604,92.702 234.925,92.702 L226.262,92.702 C232.468,85.914 236.799,76.605 236.799,66.714 C236.799,45.704 219.474,28.379 198.464,28.379 C177.454,28.379 160.129,45.704 160.129,66.714 C160.129,76.605 163.814,85.849 170.666,92.702 L160.775,92.702 C144.678,92.702 131.102,106.278 131.102,122.375 L131.102,158.836 L133.559,160.711 C150.884,173.705 170.666,181.139 191.676,182.368 L191.676,230.594 L106.343,315.927 L115.006,324.59 L197.818,241.713 L252.832,296.727 C254.061,293.624 256.517,290.521 259.62,288.711 Z M197.818,40.21 C212.04,40.21 223.806,51.976 223.806,66.198 C223.806,80.42 212.04,92.768 197.818,92.768 C183.596,92.768 171.83,81.002 171.83,66.78 C171.83,52.558 183.596,40.21 197.818,40.21 Z M143.451,152.695 L143.451,122.376 C143.451,113.132 151.467,105.051 160.776,105.051 L234.99,105.051 C244.234,105.051 252.315,113.067 252.315,122.376 L252.315,152.695 C236.153,163.814 217.6,170.02 197.818,170.02 C178.036,170.02 159.483,163.814 143.451,152.695 Z" id="Shape" fill="#666666"></path>
        <path d="M320.194,390.723 C278.756,390.723 244.752,356.719 244.752,315.281 C244.752,273.843 278.821,239.839 320.194,239.839 C361.567,239.839 395.636,273.261 395.636,315.281 C395.636,356.719 361.632,390.723 320.194,390.723 Z" id="Shape" fill="#F16051"></path>
        <g transform="translate(259.000000, 273.000000)" fill="#FFFFFF">
          <circle id="Oval" cx="61.194" cy="32.972" r="32.129"></circle>
          <path d="M98.301,65.166 L61.194,65.166 L24.152,65.166 C11.158,65.166 0.685,75.703 0.685,88.633 L0.685,122.637 L121.768,122.637 L121.768,88.633 C121.768,75.638 111.295,65.166 98.301,65.166 Z" id="Shape"></path>
        </g>
        <path d="M357.301,331.96 L348.638,331.96 C354.844,325.172 359.175,315.863 359.175,305.972 C359.175,284.962 341.85,267.637 320.84,267.637 C299.83,267.637 282.505,284.962 282.505,305.972 C282.505,315.863 286.19,325.107 293.042,331.96 L284.379,331.96 C268.282,331.96 254.706,345.536 254.706,361.633 L254.706,395.637 L267.053,395.637 L267.053,361.633 C267.053,352.389 275.069,344.308 284.378,344.308 L358.527,344.308 C367.771,344.308 375.852,352.324 375.852,361.633 L375.852,395.637 L388.199,395.637 L388.199,361.633 C386.974,345.535 373.98,331.96 357.301,331.96 Z M294.271,305.972 C294.271,291.75 306.037,279.984 320.259,279.984 C334.481,279.984 346.247,291.75 346.247,305.972 C346.247,320.194 334.481,331.96 320.259,331.96 C306.037,331.96 294.271,320.194 294.271,305.972 Z" id="Shape" fill="#666666"></path>
        <rect id="Rectangle-path" fill="#666666" x="284.962" y="370.941" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="314.053" y="370.941" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="343.725" y="370.941" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="161.939" y="131.038" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="191.612" y="131.038" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="221.285" y="131.038" width="12.347" height="12.994"></rect>
        <path d="M75.442,390.723 C34.004,390.723 0,356.719 0,315.281 C0,273.843 34.004,239.839 75.442,239.839 C116.88,239.839 150.884,273.843 150.884,315.281 C150.884,356.719 116.816,390.723 75.442,390.723 Z" id="Shape" fill="#2196F3"></path>
        <circle id="Oval" fill="#FFFFFF" cx="75.442" cy="305.972" r="32.129"></circle>
        <path d="M112.485,338.166 L75.378,338.166 L38.335,338.166 C25.341,338.166 14.868,348.703 14.868,361.633 L14.868,395.637 L136.015,395.637 L136.015,361.633 C136.016,348.638 125.479,338.166 112.485,338.166 Z" id="SVGCleanerId_0" fill="#FFFFFF"></path>
        <circle id="Oval" fill="#FFFFFF" cx="75.442" cy="305.972" r="32.129"></circle>
        <path d="M112.485,338.166 L75.378,338.166 L38.335,338.166 C25.341,338.166 14.868,348.703 14.868,361.633 L14.868,395.637 L136.015,395.637 L136.015,361.633 C136.016,348.638 125.479,338.166 112.485,338.166 Z" id="SVGCleanerId_0_1_" fill="#FFFFFF"></path>
        <path d="M112.485,331.96 L103.822,331.96 C110.028,325.172 114.359,315.863 114.359,305.972 C114.359,284.962 97.034,267.637 76.024,267.637 C55.014,267.637 37.689,284.962 37.689,305.972 C37.689,315.863 41.374,325.107 48.226,331.96 L38.335,331.96 C22.238,331.96 8.662,345.536 8.662,361.633 L8.662,395.637 L21.01,395.637 L21.01,361.633 C21.01,352.389 29.026,344.308 38.335,344.308 L112.484,344.308 C121.728,344.308 129.809,352.324 129.809,361.633 L129.809,395.637 L142.156,395.637 L142.156,361.633 C142.158,345.535 128.582,331.96 112.485,331.96 Z M49.455,305.972 C49.455,291.75 61.221,279.984 75.443,279.984 C89.665,279.984 101.431,291.75 101.431,305.972 C101.431,320.194 89.665,331.96 75.442,331.96 C61.219,331.96 49.455,320.194 49.455,305.972 Z" id="Shape" fill="#666666"></path>
        <rect id="Rectangle-path" fill="#666666" x="39.564" y="370.941" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="69.236" y="370.941" width="12.347" height="12.994"></rect>
        <rect id="Rectangle-path" fill="#666666" x="98.263" y="370.941" width="12.347" height="12.994"></rect>
      </g>
    </g>
  </svg>
)