import React from 'react'
import { Authenticate, Confirmed, Confirm, Expired } from 'components'
import { withStyles } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import Form, { rules } from './form'
import toast from 'lib/toast'
import sleep from 'lib/sleep'
import i18n from 'lib/i18n'

const SignIn = ({ classes, history }) => {
  const { user } = stores.accounts
  const { creds } = stores.auth

  const signin = async data => {
    try {
      await stores.auth.signin(data)
      await sleep(1000)
      history.push('/')
    } catch (error) {
      if (error.message !== 'Expired') {
        toast.error(i18n`Unable to signin`)
      }
      console.log(error)
    }
  }

  const cancel = () => {
    stores.auth.cancel()
  }

  const onSignIn = async (event, data) => {
    event.preventDefault()
    signin(data)
  }

  const onRetry = event => {
    event.preventDefault()
    cancel()
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Authenticate
          rules={rules}
          renderConfirm={() => (
            <Confirm
              email={creds.email}
              emailDomain={creds.emailDomain}
              code={creds.code}
              onCancel={cancel}
            />
          )}
          renderConfirmed={() => <Confirmed onCancel={cancel} name={user.name} />}
          renderExpired={() => <Expired onRetry={onRetry} />}
          renderForm={({ validate, ...state }) => (
            <Form {...state} onSignIn={e => onSignIn(e, state.data)} onChange={validate} />
          )}
        />
      </div>
    </div>
  )
}

const styles = {
  root: {
    height: `calc(100vh)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  container: {
    maxWidth: 350,
    textAlign: 'center'
  }
}

export default compose(
  withStyles(styles),
  view
)(SignIn)
