import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 512 448" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 8)" fillRule="nonzero">
        <polygon points="320 256.77 256 302.48 192 256.77 256 211.06" fill="#2196F3"/>
        <polygon points="36.571 256.77 88.685 222.03 73.142 211.06 9.142 256.77 73.142 302.48 88.685 291.51" fill="#fff"/>
        <polygon points="128 192.77 180.11 158.03 164.57 147.06 100.57 192.77 164.57 238.48 180.11 227.51" fill="#fff"/>
        <polygon points="192 128.77 237.71 92.2 292.57 128.77 237.71 165.34" fill="#2196F3"/>
        <polygon points="292.57 128.77 240.46 163.51 256 174.49 320 128.77 256 83.058 240.46 94.029" fill="#0068A4"/>
        <polygon points="283.43 320.77 329.14 284.2 384 320.77 329.14 357.34" fill="#2196F3"/>
        <polygon points="384 320.77 331.89 355.51 347.43 366.48 411.43 320.77 347.43 275.06 331.89 286.03" fill="#0068A4"/>
        <polygon points="228.57 320.77 182.86 357.34 128 320.77 182.86 284.2" fill="#2196F3"/>
        <polygon points="128 320.77 180.11 286.03 164.57 275.06 100.57 320.77 164.57 366.48 180.11 355.51" fill="#fff"/>
        <polygon points="320 384.77 274.29 421.34 219.43 384.77 274.29 348.2" fill="#2196F3"/>
        <polygon points="219.43 384.77 271.54 350.03 256 339.06 192 384.77 256 430.48 271.54 419.51" fill="#fff"/>
        <path d="m256 183.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.914-3.656 1.829-5.485 1.829zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.742-48.457 34.742z" fill="#666"/>
        <path d="m347.43 247.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.914-3.657 1.829-5.485 1.829zm-48.458-54.858l48.457 34.743 48.457-34.743-48.457-34.743-48.457 34.743z" fill="#666"/>
        <path d="m164.57 247.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.827 0.914-3.656 1.829-5.485 1.829zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.743-48.457 34.743z" fill="#666"/>
        <path d="m256 311.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.914-3.656 1.829-5.485 1.829zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.742-48.457 34.742z" fill="#666"/>
        <path d="m256 439.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.914-3.656 1.829-5.485 1.829zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.742-48.457 34.742z" fill="#666"/>
        <path d="m438.86 311.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.914-3.656 1.829-5.485 1.829zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.743-48.457 34.743z" fill="#666"/>
        <path d="m347.43 375.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.914-3.657 1.829-5.485 1.829zm-48.458-54.858l48.457 34.743 48.457-34.743-48.457-34.743-48.457 34.743z" fill="#666"/>
        <path d="m73.143 311.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.828-1.829-3.657-4.572-3.657-7.315s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.828 0.915-3.657 1.83-5.485 1.83zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.743-48.457 34.743z" fill="#666"/>
        <path d="m164.57 375.63c-1.829 0-3.657-0.914-5.486-1.829l-64-45.714c-1.829-1.829-3.657-4.571-3.657-7.314s1.829-5.486 3.657-7.314l64-45.714c2.743-1.829 7.314-1.829 10.971 0l64 45.714c2.743 1.829 3.657 4.571 3.657 7.314s-1.829 5.486-3.657 7.314l-64 45.714c-1.827 0.914-3.656 1.829-5.485 1.829zm-48.457-54.858l48.457 34.743 48.457-34.743-48.457-34.743-48.457 34.743z" fill="#666"/>
        <path d="m255 200c-7.88 0-15.104-4.312-18.832-11.256l-44.568-82.776c-5.624-10.432-8.6-22.216-8.6-34.08 0-39.64 32.248-71.888 71.888-71.888 39.864 0 72.112 32.248 72.112 71.888 0 11.856-2.976 23.648-8.6 34.088l-44.56 82.768c-3.736 6.944-10.96 11.256-18.84 11.256z" fill="#2196F3" stroke="#666" strokeWidth="15"/>
        <path d="m303.84 57.645l-42.08-30.003c-3.0802-2.1902-7.4397-2.1902-10.52 0l-42.08 30.003c-2.7941 1.9802-3.8713 5.3254-2.6931 8.3407 1.1782 3.0153 4.3763 5.0104 7.9447 5.0104h8.416v37.503c0 4.1403 3.7704 7.5006 8.416 7.5006h50.496c4.6456 0 8.416-3.3603 8.416-7.5006v-37.503h8.416c3.5684 0 6.7496-2.0102 7.9447-5.0104 1.1951-3.0003 0.10099-6.3455-2.6763-8.3407zm-31.178 5.7901v37.565h-33.333v-37.565c0-2.4642-1.3167-4.643-3.3333-6.0104l20-14.425 20 14.425c-2.0167 1.3674-3.3333 3.5461-3.3333 6.0104z" fill="#666"/>
      </g>
    </g>
  </svg>
)
