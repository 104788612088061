import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import MuiSelect from '@material-ui/core/Select'
import { withTheme } from 'hocs'

const Select = ({
  data,
  value,
  label,
  classes,
  onChange,
  required = false,
  itemValue = 'id',
  itemLabel = 'label',
  ...props
}) => (
  <FormControl className={classes.selectControl}>
    <InputLabel focused={false} required={required} htmlFor={props.id}>
      {label}
    </InputLabel>
    <MuiSelect
      value={value}
      inputProps={props}
      classes={{ selectMenu: classes.selectMenu }}
      onChange={({ target }) =>
        onChange({
          [target.name]: target.value
        })
      }
    >
      {data.map(item => (
        <MenuItem key={item.id || item[itemValue]} value={item[itemValue]}>
          {item[itemLabel]}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)

export default withTheme('form')(Select)
