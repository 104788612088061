import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import i18n from 'lib/i18n'
import Dialog from './dialog'

const noop = fn => fn
const empty = []

const AssignDialog = ({ data, className, ...props }) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  const onChange = value => {
    setSelected(isSelected(value) ? selected.filter(v => v !== value) : [...selected, value])
  }

  const onAction = ({ ok }) => {
    const assign = selected.filter(selectedId => !props.selected.find(id => id === selectedId))
    const unassign = props.selected.filter(id => !selected.find(selectedId => selectedId === id))

    props.onAction({
      ok,
      assign: assign.map(id => ({ id })),
      unassign: unassign.map(id => ({ id }))
    })

    setSelected([])
  }

  const isSelected = value => selected.indexOf(value) !== -1
  const { okLabel = i18n`Assign`, title = i18n`Assign items`, ...rest } = props
  const controls = data.map(item => ({ value: item.id, label: item.name || item.title }))

  return (
    <Dialog
      {...rest}
      maxWidth="xs"
      title={title}
      okLabel={okLabel}
      onAction={onAction}
      className={clsx('assign-dialog', className)}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {controls.map(({ value, label }) => (
            <FormControlLabel
              key={`ckeckbox-${label}${value}`}
              label={label}
              control={
                <Checkbox
                  value={value}
                  checked={isSelected(value)}
                  onChange={() => onChange(value)}
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </Dialog>
  )
}

AssignDialog.defaultProps = {
  selected: [],
  data: empty,
  onAction: noop,
  className: ''
}

// class AssignDialog extends Component {
//   static defaultProps = {
//     selected: [],
//     data: empty,
//     onAction: noop,
//     className: ''
//   }

//   state = {
//     selected: []
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.selected !== prevProps.selected) {
//       this.setSelected(this.props.selected)
//     }
//   }

//   setSelected(selected = []) {
//     this.setState({ selected })
//   }

//   onChange = value => {
//     const { selected } = this.state
//     this.setState({
//       selected: this.isSelected(value) ? selected.filter(v => v !== value) : [...selected, value]
//     })
//   }

//   onAction = ({ ok }) => {
//     const assign = this.state.selected.filter(
//       selected => !this.props.selected.find(id => id === selected)
//     )

//     const unassign = this.props.selected.filter(
//       id => !this.state.selected.find(selected => selected === id)
//     )

//     this.props.onAction({
//       ok,
//       assign: assign.map(id => ({ id })),
//       unassign: unassign.map(id => ({ id }))
//     })

//     this.setSelected()
//   }

//   isSelected = value => {
//     return this.state.selected.indexOf(value) !== -1
//   }

//   render() {
//     let {
//       data,
//       className,
//       onAction,
//       okLabel = i18n`Assign`,
//       title = i18n`Assign items`,
//       ...props
//     } = this.props

//     const controls = data.map(item => ({
//       value: item.id,
//       label: item.name || item.title
//     }))

//     return (
//       <Dialog
//         maxWidth="xs"
//         title={title}
//         okLabel={okLabel}
//         onAction={this.onAction}
//         className={clsx('assign-dialog', className)}
//         {...props}
//       >
//         <FormControl component="fieldset">
//           <FormGroup>
//             {controls.map(({ value, label }) => (
//               <FormControlLabel
//                 key={`ckeckbox-${label}${value}`}
//                 label={label}
//                 control={
//                   <Checkbox
//                     value={value}
//                     checked={this.isSelected(value)}
//                     onChange={() => this.onChange(value)}
//                   />
//                 }
//               />
//             ))}
//           </FormGroup>
//         </FormControl>
//       </Dialog>
//     )
//   }
// }

export default AssignDialog
