import React, { Fragment, useReducer, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import fileDownload from 'react-file-download'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { PlaceHolder, Button, Table, CalendarImg, DeleteDialog, PeriodDialog } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import { mapStatus } from 'data/reservation/maps'
import { SEARCH_KEYS, CANCELLED, APPROVED } from 'data/reservation/constants'
import createSearch from 'lib/search'
import { timeAgo } from 'lib/utils'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const ReservationList = ({ classes, match, history }) => {
  const [state, setState] = useReducer(reducer, {
    downloadDialog: false,
    deleteDialog: false,
    cancelDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const downloadPeriods = [
    {
      label: i18n`Current month`,
      value: '0'
    },
    {
      label: i18n`Last month`,
      value: '-1'
    },
    {
      label: i18n`Last two months`,
      value: '-2'
    },
    {
      label: i18n`Last four months`,
      value: '-4'
    },
    {
      label: i18n`Last six months`,
      value: '-6'
    },
    {
      label: i18n`Last twelve months`,
      value: '-12'
    }
  ]

  const columns = [
    { id: 'owner.name', label: i18n`Name` },
    { id: 'area.title', label: i18n`Area` },
    { id: 'updated', label: i18n`Updated` },
    { id: 'status', label: i18n`Status` }
  ]

  const search = createSearch(SEARCH_KEYS)
  const { domain } = match.params
  const { list } = stores.reservations
  const { keyword, guide, cancelDialog, deleteDialog, downloadDialog } = state
  const data = keyword ? search(list, keyword) : list

  const selected = state.selected.map(id => ({ id }))

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    try {
      const promises = [stores.reservations.fetch(domain), stores.areas.fetch(domain)]
      await Promise.all(promises)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching reservations`)
    }
  }

  const approve = async data => {
    try {
      await stores.reservations.approve(domain, data)
      toast.success(i18n`Approved`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to approve reservation`)
    }
    reset()
  }

  const cancel = async data => {
    try {
      await stores.reservations.cancel(domain, data)
      toast.success(i18n`Canceled`)
    } catch (error) {
      console.log(error, data)
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to cancel reservation`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.reservations.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.areas.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete reservation(s)`)
    }
    reset()
  }

  const download = async data => {
    try {
      const file = await stores.reservations.download(domain, data)
      fileDownload(file, 'reservations.csv')
    } catch (error) {
      console.log(error)
      toast.error(i18n`Unable to download reservations`)
    }
    reset()
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onCancelAction = ({ ok }) => {
    if (ok) cancel(state.data)
    else reset()
    closeCancelDialog()
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onApprove = data => {
    approve(data)
  }

  const onDownload = () => {
    if (selected.length > 0) {
      return download(selected)
    }

    openDownloadDialog()
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const openCancelDialog = data => {
    setState({ cancelDialog: true, data })
  }

  const closeCancelDialog = () => {
    setState({ cancelDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const onDownloadAction = ({ ok, period }) => {
    if (ok) download({ period })
    closeDownloadDialog()
  }

  const closeDownloadDialog = () => {
    setState({ downloadDialog: false })
  }

  const openDownloadDialog = () => {
    setState({ downloadDialog: true })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <CalendarImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE A RESERVATION`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5">{i18n`No reservations to show at this moment`}</Typography>
      </PlaceHolder>
    )
  }

  const renderRow = (node, key, data) => {
    if (key === 'status') {
      node = (
        <Typography className={classes[`STATUS_${data.status}`]}>
          {mapStatus(data.status)}
        </Typography>
      )
    }

    if (key === 'owner.name') {
      node = data.owner.name
    }

    if (key === 'area.title') {
      node = data.area.title
    }

    if (key === 'custom-action') {
      return (
        data.status !== CANCELLED && (
          <Fragment>
            {data.status !== APPROVED && (
              <Tooltip title={i18n`Approve`}>
                <IconButton
                  aria-label={i18n`Approve`}
                  className={classes.approveButton}
                  onClick={() => onApprove(data)}
                >
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={i18n`Cancel`}>
              <IconButton
                aria-label={i18n`Cancel`}
                className={classes.cancelButton}
                onClick={() => openCancelDialog(data)}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )
      )
    }

    if (key === 'updated') {
      node = timeAgo(data.updated)
    }

    return node
  }

  return (
    <Fragment>
      <Table
        noInvite
        noAssign
        noSwitch
        noUpload
        noItemDelete
        noItemSwitch
        data={data}
        selected={state.selected}
        columns={columns}
        className="reservations"
        title={i18n`Reservations`}
        onRow={page}
        onDetail={page}
        onSearch={onSearch}
        onGuide={openGuide}
        onSelected={onSelected}
        onDownload={onDownload}
        onDelete={openDeleteDialog}
        placeHolder={renderPlaceHolder}
        onCreate={() => page('create')}
        row={renderRow}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete reservations`} onAction={onDeleteAction}>
        <p>
          {selected.length > 1
            ? i18n`Are you sure you want to delete the selected reservations?`
            : i18n`Are you sure you want to delete this reservation?`}
        </p>
      </DeleteDialog>
      <DeleteDialog
        open={cancelDialog}
        title={i18n`Cancel reservation`}
        okLabel={i18n`Cancel reservation`}
        onAction={onCancelAction}
      >
        <p>
          {i18n`Are you sure you want to cancel this reservation? Once a reservation is canceled it can't be approved again.`}
        </p>
      </DeleteDialog>
      <PeriodDialog
        open={downloadDialog}
        periods={downloadPeriods}
        onAction={onDownloadAction}
        onClose={closeDownloadDialog}
        title={i18n`Download period`}
        okLabel={i18n`Download`}
      />
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default compose(
  withTheme('reservations'),
  view
)(ReservationList)
