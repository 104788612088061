import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import { CalendarImg } from 'components'
import PlaceHolder from 'components/placeholder'
import Button from 'components/button'
import Table from 'components/table'
import UploadDialog from 'components/upload-dialog'
import DeleteDialog from 'components/delete-dialog'
import { withTheme } from 'hocs'
import { SEARCH_KEYS, MIME_TYPES } from 'data/document/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import bytesToSize from 'lib/bytes-to-size'
import { timeAgo, truncate } from 'lib/utils'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

class DocumentList extends Component {
  state = {
    deleteDialog: false,
    uploadDialog: false,
    selected: [],
    progress: 0,
    guide: false,
    keyword: ''
  }

  search = createSearch(SEARCH_KEYS)

  accept = MIME_TYPES.join(',')

  get domain() {
    return this.props.match.params.domain
  }

  get data() {
    const list = stores.documents.list
    const { keyword } = this.state
    return keyword ? this.search(list, keyword) : list
  }

  get columns() {
    return [
      { id: 'name', label: i18n`Name` },
      { id: 'size', label: i18n`Size` },
      { id: 'views', label: i18n`Views` },
      { id: 'created', label: i18n`Uploaded` }
    ]
  }

  get selected() {
    return this.state.selected.map(id => ({ id }))
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (this.domain !== prevProps.match.params.domain) {
      this.fetch()
    }
  }

  reset = () => {
    this.setState({ selected: [], progress: 0 })
  }

  fetch = async () => {
    try {
      await stores.documents.fetch(this.domain)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching documents`)
    }
  }

  destroy = async data => {
    try {
      await stores.documents.destroy(this.domain, data)
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete document(s)`)
    }
    this.reset()
  }

  upload = async data => {
    try {
      await stores.documents.upload(this.domain, data.file, { onProgress: this.onProgress })
      toast.success(i18n`Uploaded`)
      this.closeUploadDialog()
      this.reset()
    } catch (error) {
      toast.error(i18n`Unable to upload video`)
    }
  }

  onProgress = progress => {
    this.setState({ progress })
  }

  onUploadAction = ({ ok, data }) => {
    if (ok) this.upload(data)
    else this.closeUploadDialog()
  }

  onDeleteAction = ({ ok }) => {
    if (ok) this.destroy(this.selected)
    else this.reset()
    this.closeDeleteDialog()
  }

  onSelected = selected => {
    this.setState({ selected })
  }

  onSearch = keyword => {
    this.setState({ keyword })
  }

  openUploadDialog = () => {
    this.setState({ uploadDialog: true })
  }

  closeUploadDialog = () => {
    this.setState({ uploadDialog: false })
  }

  openDeleteDialog = () => {
    this.setState({ deleteDialog: true })
  }

  closeDeleteDialog = () => {
    this.setState({ deleteDialog: false })
  }

  openGuide = () => {
    this.setState({ guide: true })
  }

  closeGuide = () => {
    this.setState({ guide: false })
  }

  renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <CalendarImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={this.openUploadDialog}>{i18n`UPLOAD A DOCUMENT`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5">{i18n`No documents to show at this moment`}</Typography>
      </PlaceHolder>
    )
  }

  renderRow = (node, key, data) => {
    const { classes } = this.props

    if (key === 'created') {
      node = <div className={classes.noWrap}>{timeAgo(data.created)}</div>
    } else if (key === 'name') {
      node = (
        <a href={data.url} title={data.name} target="_blank">
          {truncate(decodeURI(data.name), 50)}
        </a>
      )
    } else if (key === 'body') {
      node = truncate(data.body, 50)
    } else if (key === 'size') {
      node = bytesToSize(data.size)
    }

    return node
  }

  render() {
    const { guide, selected, progress, uploadDialog, deleteDialog } = this.state

    return (
      <Fragment>
        <Table
          {...this.props}
          noInvite
          noAssign
          noSwitch
          noUpload
          noActions
          noDownload
          noItemSwitch
          noItemDelete
          noSelectedDownload
          data={this.data}
          selected={selected}
          columns={this.columns}
          className="documents"
          title={i18n`Documents`}
          onCreate={this.openUploadDialog}
          onSearch={this.onSearch}
          onGuide={this.openGuide}
          onSelected={this.onSelected}
          onDelete={this.openDeleteDialog}
          placeHolder={this.renderPlaceHolder}
          row={this.renderRow}
        />
        <DeleteDialog
          open={deleteDialog}
          title={i18n`Delete documents`}
          onAction={this.onDeleteAction}
        >
          <p>
            {selected.length > 1
              ? i18n`Are you sure you want to delete the selected documents?`
              : i18n`Are you sure you want to delete this document?`}
          </p>
        </DeleteDialog>
        <UploadDialog
          open={uploadDialog}
          title={i18n`Upload document`}
          text={i18n`Drag and drop your document here or`}
          accept={this.accept}
          progress={progress}
          onAction={this.onUploadAction}
        />
        <Guide open={guide} onClose={this.closeGuide} />
      </Fragment>
    )
  }
}

export default compose(
  withTheme('documents'),
  view
)(DocumentList)
