import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 512 512" {...props}>
  	<g fill="none">
  		<g>
  			<path d="m141.86 473.6l9.165-115.2h28.177c3.806 0 7.39-1.673 9.822-4.591 2.441-2.935 3.447-6.75 2.773-10.487l-25.6-140.8c-0.137-0.725-0.393-1.553-0.794-2.534l-3.84-9.378 145.65-103.94 196.26 156.53h-55.467v230.4h-306.14z"/>
  			<path d="m102.4 140.8c-35.294 0-64-28.706-64-64s28.706-64 64-64 64 28.706 64 64-28.706 64-64 64z" fill="#2196F3"/>
  			<polygon fill="#2196F3" points="64.979 499.2 53.775 358.4 10.264 358.4 40.514 192 164.28 192 194.53 358.4 151.01 358.4 139.82 499.2"/>
  			<g fill="#666">
  				<path d="m501.28 209.57l-179.2-128c-4.454-3.183-9.668-4.77-14.882-4.77s-10.428 1.587-14.882 4.77l-137.03 97.783c-0.563-0.043-1.109-0.154-1.69-0.154h-102.4c-12.373 0-22.972 8.849-25.182 21.026l-25.6 140.8c-1.357 7.467 0.666 15.155 5.53 20.983s12.066 9.19 19.652 9.19h16.358l9.327 117.23c1.058 13.305 12.16 23.571 25.515 23.571h51.2c13.355 0 24.465-10.266 25.523-23.569l0.162-2.031h281.52c14.14 0 25.6-11.46 25.6-25.6v-204.8h25.6c11.127 0 20.983-7.194 24.371-17.783 3.405-10.599-0.435-22.179-9.489-28.647zm-362.09 136.03l-11.196 140.8h-51.2l-11.196-140.8h-40.004l25.6-140.8h38.4v77.875c0 4.716 8.5333-21.242 25.6-77.875h38.4l25.6 140.8h-40.004zm296-115.2v230.4h-279.48l7.125-89.6h16.358c7.595 0 14.788-3.371 19.652-9.199s6.886-13.508 5.53-20.983l-25.6-140.8c-0.324-1.783-0.879-3.465-1.536-5.094l129.96-92.724 179.2 128h-51.2z"/>
  				<path d="m102.4 153.6c42.351 0 76.8-34.449 76.8-76.8s-34.449-76.8-76.8-76.8c-42.342 0-76.8 34.458-76.8 76.8s34.458 76.8 76.8 76.8zm0-128c28.279 0 51.2 22.921 51.2 51.2s-22.921 51.2-51.2 51.2-51.2-22.921-51.2-51.2 22.92-51.2 51.2-51.2z"/>
  			</g>
  			<path d="m397 237h-80v80h80v-80zm-16 32h-16v-16h16v16zm-32-16v16h-16v-16h16zm-16 32h16v16h-16v-16zm32 16v-16h16v16h-16z" fill="#666"/>
  			<path d="m275 237h-80v80h80v-80zm-16 32h-16v-16h16v16zm-32-16v16h-16v-16h16zm-16 32h16v16h-16v-16zm32 16v-16h16v16h-16z" fill="#666"/>
  			<rect height="96" width="112" fill="#2196F3" x="235" y="365"/>
  			<rect height="16" width="16" fill="#666" x="248" y="405"/>
  		</g>
  	</g>
  </svg>
)
