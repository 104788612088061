import React, { Fragment, useReducer, useEffect } from 'react'
import formatTime from 'date-fns/format'
import isDate from 'date-fns/isValid'
import { Section } from 'components'
import { DeleteDialog } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { pick, formatDuration } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const AreaDetail = props => {
  const { match, history } = props
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    deleteDialog: false
  })

  const { id, domain } = match.params
  const area = stores.areas.get(id)

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const isSameData =
    area &&
    area.title === state.data.title &&
    (area.coords || []).join(',') === state.data.coords &&
    area.reservable === state.data.reservable &&
    area.active === state.data.active

  const reset = () => {
    setState({ data: {}, errors: {}, isValid: false })
  }

  const fetch = async () => {
    try {
      await stores.areas.fetch(domain, { id: id })
    } catch (err) {
      toast.error(i18n`Unable to fetch area information`)
      back()
    }
  }

  const update = async data => {
    try {
      data = normalize(data)
      await stores.areas.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update area`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.areas.destroy(domain, data)
      back()
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete area`)
      }
    }
  }

  const normalize = ({ startTime, endTime, weekDays, duration, terms, ...data }) => {
    if (data.reservable) {
      data.terms = terms
      data.startTime = isDate(startTime) ? formatTime(startTime, 'HH:mm') : startTime
      data.endTime = isDate(endTime) ? formatTime(endTime, 'HH:mm') : endTime
      data.weekDays = weekDays.map(day => parseInt(day, 10)).sort()
      data.duration = formatDuration(duration)
    } else {
      data = pick(data, ['id', 'title', 'coords', 'reservable', 'active'])
    }

    data.active = 'active' in data ? Boolean(data.active) : true

    if ('coords' in data) {
      data.coords = data.coords ? data.coords.split(',') : []
    }
    return data
  }

  const back = () => {
    history.push(`/resources/${domain}/areas`)
    return null
  }

  const onFormChange = (data, isValid, errors) => {
    setState({
      data: { ...state.data, ...data },
      errors: typeof errors !== 'undefined' ? errors : state.errors,
      isValid: typeof isValid !== 'undefined' ? isValid : state.isValid
    })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id })
    closeDeleteDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  if (!area) return back()

  const { isValid, deleteDialog } = state
  const data = { ...area, coords: (area.coords || []).join(','), ...state.data }

  return (
    <Fragment>
      <Section
        {...props}
        noAssign
        data={data}
        title={i18n`Area Detail`}
        backButtonLabel={i18n`Areas`}
        onBack={back}
        onCancel={back}
        onSubmit={onUpdate}
        onDelete={openDeleteDialog}
        disableSubmitButton={!isValid || (!data.reservable && isSameData)}
      >
        <Form isUpdate data={data} onChange={onFormChange} />
      </Section>
      <DeleteDialog open={deleteDialog} title={i18n`Delete area`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this area?`}</p>
      </DeleteDialog>
    </Fragment>
  )
}

export default view(AreaDetail)
