import React, { useReducer, useEffect } from 'react'
import { Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { extractNumberFromString } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const UnitCreate = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false
  })

  const { domain } = match.params

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const fetch = async () => {
    try {
      await stores.residents.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to fetch residents`)
    }
  }

  const create = async data => {
    try {
      data.active = 'active' in data ? Boolean(data.active) : true
      await stores.units.create(domain, data)
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message.indexOf('units limit')) {
        const limit = extractNumberFromString(error.message) || ''
        toast.warn(
          i18n`You have reached your free ${limit} units limit. Upgrade your plan for more.`
        )
      } else if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create unit`)
      }
    }
  }

  const back = () => {
    history.push(`/resources/${domain}/units`)
  }

  const onFormChange = (data, isValid, errors) => {
    setState({ data, isValid, errors })
  }

  const onCreate = () => {
    create(state.data)
  }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={i18n`Create Unit`}
      backButtonLabel={i18n`Units`}
      disableSubmitButton={!state.isValid}
    >
      <Form {...state} onChange={onFormChange} units={stores.units.list} />
    </Section>
  )
}

export default view(UnitCreate)
