import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 496 496" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g transform="translate(32 288)" fill="#2196F3">
          <path d="m48 96c-26.472 0-48-21.528-48-48s21.528-48 48-48 48 21.528 48 48-21.528 48-48 48z"/>
          <path d="m224 96c-26.472 0-48-21.528-48-48s21.528-48 48-48 48 21.528 48 48-21.528 48-48 48z"/>
          <path d="m384 96c-26.472 0-48-21.528-48-48s21.528-48 48-48 48 21.528 48 48-21.528 48-48 48z"/>
        </g>
        <rect x="272" y="160" width="96" height="16" fill="#666"/>
        <rect x="272" y="192" width="48" height="16" fill="#666"/>
        <rect x="32" y="240" width="96" height="16" fill="#666"/>
        <path d="m480 208v-20.12l-64-45.712-16 11.432v-25.6h-16v-32h-32v-48h-48v-8c0-13.232-10.768-24-24-24h-24v-16h-16v16h-24c-13.232 0-24 10.768-24 24v8h-48v48h-96v32h-16v48h-16v32h-16v192 8 88h496v-88-8-192h-16zm-16-11.88v11.88h-96v-11.88l48-34.288 48 34.288zm-96-68.12h-96v-16h96v16zm-208-64h96v-16h-48v-8c0-4.408 3.584-8 8-8h64c4.416 0 8 3.592 8 8v8h-16v16h64v32h-80v32h-16v162.8c5.016-1.784 10.384-2.8 16-2.8v-144h128v21.024l-32 22.856v20.12h-16v16h-64v16h64v16h-64v16h64v128h-72v-48h-16v48h-56v-272h-16v-32h-16v-32zm-112 80h80v-16h-64v-16h96v16h-16v16h32v256h-16v-192h-16v-32h-96v-32zm-16 48h96v16h-96v-16zm-16 32h128v176h-56v-48h-16v48h-56v-176zm0 256v-32h432v-16h-432v-16h464v16h-16v16h16v32h-464zm408-80v-48h-16v48h-56v-176h128v176h-56z" fill="#666"/>
        <rect x="368" y="240" width="96" height="16" fill="#666"/>
        <rect x="192" y="88" width="16" height="16" fill="#666"/>
        <rect x="224" y="88" width="16" height="16" fill="#666"/>
        <rect x="208" y="128" width="16" height="16" fill="#666"/>
        <rect x="208" y="208" width="16" height="16" fill="#666"/>
        <rect x="208" y="240" width="16" height="16" fill="#666"/>
      </g>
    </g>
  </svg>
)