import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'residents')
const merge = createMerge(store)
const list = createList(store, 'list')

const fetch = async force => {
  const res = (await api.fetch()) || []
  return list.replace(res)
}

const revoke = async data => {
  const res = await api.revoke(data)
  return list.remove(res)
}

const event = ({ type, payload }) => {
  switch (type) {
    case cons.SESSION_CREATED:
    case cons.SESSION_UPDATED:
      return list.add(payload)
    case cons.SESSION_REVOKED:
      return list.remove(payload)
    default:
      break
  }
}

export default merge({
  fetch,
  revoke,
  event
})
