import React, { Fragment, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import InputAdornment from '@material-ui/core/InputAdornment'
import InfoIcon from '@material-ui/icons/Info'
import Input from '@material-ui/core/Input'
import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import AddIcon from '@material-ui/icons/Add'
import { withTheme } from 'hocs'
import debounce from 'lib/debounce'
import i18n from 'lib/i18n'
import Table from './table'

const noop = () => {}
const identity = f => f

const DataTable = React.memo(
  ({
    title,
    classes,
    columns,
    selected,
    onGuide,
    onDetail,
    onSwitch,
    onUpload,
    onCreate,
    onInvite,
    onDelete,
    onAssign,
    onDownload,
    onChipDelete,
    noSearch,
    noUpload,
    noCreate,
    noDownload,
    noGuide,
    noDelete,
    noInvite,
    noAssign,
    noActions,
    noCheckbox,
    noSelectedDownload,
    noItemDetails,
    noItemSwitch,
    noItemDelete,
    ...props
  }) => {
    const [keyword, setKeyword] = useState('')

    const onSearch = debounce(keyword => {
      setKeyword(keyword)
      props.onSearch(keyword)
    }, 600)

    const onRow = (e, data) => {
      if (e.target.tagName.toLowerCase() === 'td') {
        props.onRow(data)
      }
    }

    const renderCell = (row, key) => {
      if (Array.isArray(row[key])) {
        if (/residents|units/.test(key)) {
          return row[key]
            .sort((a, b) => {
              const av = a.name || a.title || a.code || a.id
              const bv = b.name || b.title || b.code || b.id
              if (av < bv) return -1
              if (av > bv) return 1
              return 0
            })
            .map(data => (
              <Chip
                className={classes.chip}
                key={data.id || data.code}
                label={data.name || data.title}
                classes={{ deleteIcon: classes.chipDeleteIcon }}
                onDelete={() => onChipDelete([row.id, data.id])}
              />
            ))
        }
        return row[key].join(',')
      }
      return row[key]
    }

    const hasData = Boolean(props.data.length)

    return (
      <Table
        {...props}
        columns={columns}
        selected={selected}
        noActions={noActions}
        noCheckbox={noCheckbox}
        searching={Boolean(keyword)}
        title={count =>
          count > 0 ? (
            <Typography variant="subtitle1">{i18n`${count} selected`}</Typography>
          ) : (
            <Typography variant="h4">{title}</Typography>
          )
        }
        actions={count =>
          count > 0 ? (
            <Fragment>
              {!noSelectedDownload && (
                <Tooltip title={i18n`Download`}>
                  <IconButton
                    className="download-action"
                    aria-label={i18n`Download`}
                    onClick={() => onDownload(selected)}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!noAssign && (
                <Tooltip title={i18n`Assign`}>
                  <IconButton
                    className="assign-action"
                    aria-label={i18n`Assign`}
                    onClick={() => onAssign(selected)}
                  >
                    <PersonAddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!noDelete && (
                <Tooltip title={i18n`Delete`}>
                  <IconButton
                    className="delete-action"
                    aria-label={i18n`Delete`}
                    onClick={() => onDelete(selected)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {!noSearch && (
                <Input
                  className={clsx('search', classes.input)}
                  onChange={e => onSearch(e.target.value)}
                  placeholder={i18n`Search`}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  }
                />
              )}
              {!noCreate && (
                <Tooltip title={i18n`Create`}>
                  <IconButton
                    className="create-action"
                    aria-label={i18n`Create`}
                    onClick={onCreate}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!noUpload && (
                <Tooltip title={i18n`Upload`}>
                  <IconButton
                    className="upload-action"
                    aria-label={i18n`Upload`}
                    onClick={onUpload}
                  >
                    <PublishIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!noDownload && (
                <Tooltip title={i18n`Download`}>
                  <IconButton
                    className="download-action"
                    aria-label={i18n`Download`}
                    onClick={() => onDownload()}
                    disabled={!hasData}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!noGuide && (
                <Tooltip title={i18n`Guide`}>
                  <IconButton className="guide-action" aria-label={i18n`Guide`} onClick={onGuide}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          )
        }
        header={(row, { onSort, orderBy, order }) => (
          <TableCell
            key={row.id}
            numeric={row.numeric}
            padding={row.noPadding ? 'none' : 'default'}
            className={row.last ? classes.lastCell : ''}
            sortDirection={orderBy === row.id ? order : false}
          >
            <Tooltip
              title={i18n`Sort`}
              placement={row.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                direction={order}
                active={orderBy === row.id}
                onClick={() => onSort(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        )}
        rows={(row, { isSelected, onSelect }) => (
          <TableRow
            hover
            key={row.id}
            tabIndex={-1}
            role="checkbox"
            aria-checked={isSelected}
            onClick={e => onRow(e, row.id)}
          >
            {!noCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox checked={isSelected} onClick={event => onSelect(row.id)} />
              </TableCell>
            )}

            {columns.map(({ id, last, numeric, chip, noPadding }) => (
              <TableCell
                key={id + row.id}
                numeric={numeric}
                className={clsx(`cell-${id}`, last ? classes.lastItemCell : '')}
                padding={row.noPadding ? 'none' : 'default'}
              >
                {props.row(renderCell(row, id), id, row)}
              </TableCell>
            ))}

            {!noActions && (
              <TableCell className={classes.lastCell}>
                {props.row(null, 'custom-action', row)}
                {!noItemSwitch &&
                  props.row(
                    <Switch
                      onChange={e => onSwitch({ id: row.id, active: e.target.checked })}
                      checked={Boolean(row.active)}
                      name="active"
                    />,
                    'switch',
                    row
                  )}
                {!noItemDelete &&
                  props.row(
                    <Tooltip title={i18n`Delete`}>
                      <IconButton aria-label={i18n`Delete`} onClick={() => onDelete(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>,
                    'delete',
                    row
                  )}
                {!noItemDetails &&
                  props.row(
                    <Tooltip title={i18n`Details`}>
                      <IconButton aria-label={i18n`Details`} onClick={() => onDetail(row.id)}>
                        <ChevronRightIcon />
                      </IconButton>
                    </Tooltip>,
                    'detail',
                    row
                  )}
              </TableCell>
            )}
          </TableRow>
        )}
      />
    )
  }
)

DataTable.defaultProps = {
  row: identity,
  onRow: noop,
  onGuide: noop,
  onDetail: noop,
  onSwitch: noop,
  onUpload: noop,
  onCreate: noop,
  onSearch: noop,
  onInvite: noop,
  onDelete: noop,
  onAssign: noop,
  onSelected: noop,
  onDownload: noop,
  onChipDelete: noop,
  arrayWithChip: false,
  selected: [],
  columns: [],
  data: [],
  placeHolder: () => null
}

// class DataTable extends PureComponent {
//   static defaultProps = {
//     row: identity,
//     onRow: noop,
//     onGuide: noop,
//     onDetail: noop,
//     onSwitch: noop,
//     onUpload: noop,
//     onCreate: noop,
//     onSearch: noop,
//     onInvite: noop,
//     onDelete: noop,
//     onAssign: noop,
//     onSelected: noop,
//     onDownload: noop,
//     onChipDelete: noop,
//     arrayWithChip: false,
//     selected: [],
//     columns: [],
//     data: [],
//     placeHolder: () => null
//   }

//   state = {
//     keyword: ''
//   }

//   onSearch = debounce(keyword => {
//     this.setState({ keyword })
//     this.props.onSearch(keyword)
//   }, 600)

//   onRow = (e, data) => {
//     if (e.target.tagName.toLowerCase() === 'td') {
//       this.props.onRow(data)
//     }
//   }

//   renderCell = (row, key) => {
//     if (Array.isArray(row[key])) {
//       if (/residents|units/.test(key)) {
//         const { classes, onChipDelete } = this.props
//         return row[key]
//           .sort((a, b) => {
//             const av = a.name || a.title || a.code || a.id
//             const bv = b.name || b.title || b.code || b.id
//             if (av < bv) return -1
//             if (av > bv) return 1
//             return 0
//           })
//           .map(data => (
//             <Chip
//               className={classes.chip}
//               key={data.id || data.code}
//               label={data.name || data.title}
//               classes={{ deleteIcon: classes.chipDeleteIcon }}
//               onDelete={() => onChipDelete([row.id, data.id])}
//             />
//           ))
//       }
//       return row[key].join(',')
//     }
//     return row[key]
//   }

//   render() {
//     const {
//       title,
//       classes,
//       columns,
//       selected,
//       onGuide,
//       onDetail,
//       onSwitch,
//       onUpload,
//       onCreate,
//       onInvite,
//       onDelete,
//       onAssign,
//       onDownload,
//       noSearch,
//       noUpload,
//       noCreate,
//       noDownload,
//       noGuide,
//       noDelete,
//       noInvite,
//       noAssign,
//       noActions,
//       noCheckbox,
//       noSelectedDownload,
//       noItemDetails,
//       noItemSwitch,
//       noItemDelete,
//       ...props
//     } = this.props

//     const { keyword } = this.state
//     const hasData = Boolean(this.props.data.length)

//     return (
//       <Table
//         {...props}
//         columns={columns}
//         selected={selected}
//         noActions={noActions}
//         noCheckbox={noCheckbox}
//         searching={Boolean(keyword)}
//         title={count =>
//           count > 0 ? (
//             <Typography variant="subtitle1">{i18n`${count} selected`}</Typography>
//           ) : (
//             <Typography variant="h4">{title}</Typography>
//           )
//         }
//         actions={count =>
//           count > 0 ? (
//             <Fragment>
//               {!noSelectedDownload && (
//                 <Tooltip title={i18n`Download`}>
//                   <IconButton
//                     className="download-action"
//                     aria-label={i18n`Download`}
//                     onClick={() => onDownload(selected)}
//                   >
//                     <GetAppIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//               {!noAssign && (
//                 <Tooltip title={i18n`Assign`}>
//                   <IconButton
//                     className="assign-action"
//                     aria-label={i18n`Assign`}
//                     onClick={() => onAssign(selected)}
//                   >
//                     <PersonAddIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//               {!noDelete && (
//                 <Tooltip title={i18n`Delete`}>
//                   <IconButton
//                     className="delete-action"
//                     aria-label={i18n`Delete`}
//                     onClick={() => onDelete(selected)}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//             </Fragment>
//           ) : (
//             <Fragment>
//               {!noSearch && (
//                 <Input
//                   className={clsx('search', classes.input)}
//                   onChange={e => this.onSearch(e.target.value)}
//                   placeholder={i18n`Search`}
//                   startAdornment={
//                     <InputAdornment position="start">
//                       <SearchIcon className={classes.searchIcon} />
//                     </InputAdornment>
//                   }
//                 />
//               )}
//               {!noCreate && (
//                 <Tooltip title={i18n`Create`}>
//                   <IconButton
//                     className="create-action"
//                     aria-label={i18n`Create`}
//                     onClick={onCreate}
//                   >
//                     <AddIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//               {!noUpload && (
//                 <Tooltip title={i18n`Upload`}>
//                   <IconButton
//                     className="upload-action"
//                     aria-label={i18n`Upload`}
//                     onClick={onUpload}
//                   >
//                     <PublishIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//               {!noDownload && (
//                 <Tooltip title={i18n`Download`}>
//                   <IconButton
//                     className="download-action"
//                     aria-label={i18n`Download`}
//                     onClick={() => onDownload()}
//                     disabled={!hasData}
//                   >
//                     <GetAppIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//               {!noGuide && (
//                 <Tooltip title={i18n`Guide`}>
//                   <IconButton className="guide-action" aria-label={i18n`Guide`} onClick={onGuide}>
//                     <InfoIcon />
//                   </IconButton>
//                 </Tooltip>
//               )}
//             </Fragment>
//           )
//         }
//         header={(row, { onSort, orderBy, order }) => (
//           <TableCell
//             key={row.id}
//             numeric={row.numeric}
//             padding={row.noPadding ? 'none' : 'default'}
//             className={row.last ? classes.lastCell : ''}
//             sortDirection={orderBy === row.id ? order : false}
//           >
//             <Tooltip
//               title={i18n`Sort`}
//               placement={row.numeric ? 'bottom-end' : 'bottom-start'}
//               enterDelay={300}
//             >
//               <TableSortLabel
//                 direction={order}
//                 active={orderBy === row.id}
//                 onClick={() => onSort(row.id)}
//               >
//                 {row.label}
//               </TableSortLabel>
//             </Tooltip>
//           </TableCell>
//         )}
//         rows={(row, { isSelected, onSelect }) => (
//           <TableRow
//             hover
//             key={row.id}
//             tabIndex={-1}
//             role="checkbox"
//             aria-checked={isSelected}
//             onClick={e => this.onRow(e, row.id)}
//           >
//             {!noCheckbox && (
//               <TableCell padding="checkbox">
//                 <Checkbox checked={isSelected} onClick={event => onSelect(row.id)} />
//               </TableCell>
//             )}

//             {columns.map(({ id, last, numeric, chip, noPadding }) => (
//               <TableCell
//                 key={id + row.id}
//                 numeric={numeric}
//                 className={clsx(`cell-${id}`, last ? classes.lastItemCell : '')}
//                 padding={row.noPadding ? 'none' : 'default'}
//               >
//                 {this.props.row(this.renderCell(row, id), id, row)}
//               </TableCell>
//             ))}

//             {!noActions && (
//               <TableCell className={classes.lastCell}>
//                 {this.props.row(null, 'custom-action', row)}
//                 {!noItemSwitch &&
//                   this.props.row(
//                     <Switch
//                       onChange={e => onSwitch({ id: row.id, active: e.target.checked })}
//                       checked={Boolean(row.active)}
//                       name="active"
//                     />,
//                     'switch',
//                     row
//                   )}
//                 {!noItemDelete &&
//                   this.props.row(
//                     <Tooltip title={i18n`Delete`}>
//                       <IconButton aria-label={i18n`Delete`} onClick={() => onDelete(row)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </Tooltip>,
//                     'delete',
//                     row
//                   )}
//                 {!noItemDetails &&
//                   this.props.row(
//                     <Tooltip title={i18n`Details`}>
//                       <IconButton aria-label={i18n`Details`} onClick={() => onDetail(row.id)}>
//                         <ChevronRightIcon />
//                       </IconButton>
//                     </Tooltip>,
//                     'detail',
//                     row
//                   )}
//               </TableCell>
//             )}
//           </TableRow>
//         )}
//       />
//     )
//   }
// }

export default withTheme('table')(DataTable)
