import { get, post, put, del } from 'lib/api'

export const fetch = (domain, data) => get(`/${domain}/admins`, data)
export const create = (domain, data) => post(`/${domain}/admins`, data)

export const upload = (domain, { file }) => {
  const data = new FormData()
  data.append('file', file)
  return post(`/${domain}/admins/import`, data, { isFile: true })
}

export const invite = (domain, data) => post(`/${domain}/residents/invite`, data)
export const download = (domain, data) => get(`/${domain}/admins/export`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/admins/${id}`, data)
export const destroy = (domain, data) => del(`/${domain}/admins`, data)
