import React, { useReducer, useEffect } from 'react'
import toast from 'lib/toast'
import { Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const ResidentCreate = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false
  })

  const { domain } = match.params

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const fetch = async () => {
    try {
      await stores.units.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to fetch units`)
    }
  }

  const create = async data => {
    try {
      data.active = 'active' in data ? Boolean(data.active) : true
      await stores.residents.create(domain, data)
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create resident`)
      }
    }
  }

  const back = () => {
    history.push(`/members/${domain}/residents`)
  }

  const onFormChange = (data, isValid, errors) => {
    setState({ data, isValid, errors })
  }

  const onCreate = () => {
    create(state.data)
  }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={i18n`Create Resident`}
      backButtonLabel={i18n`Residents`}
      disableSubmitButton={!state.isValid}
    >
      <Form {...state} onChange={onFormChange} units={stores.units.list} />
    </Section>
  )
}

export default view(ResidentCreate)
