import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.residents',
        content: (
          <div>
            <h2>{i18n`Resident List`}</h2>
            <span>{i18n`This is the resident list section where all residents that belong to this community will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.residents .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create, import, export residents.`}</span>
          </div>
        )
      },
      {
        selector: '.residents .search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for residents.`}</span>
          </div>
        )
      },
      {
        selector: '.residents .create-action',
        content: (
          <div>
            <h2>{i18n`Create Resident`}</h2>
            <span>{i18n`Create a new resident.`}</span>
          </div>
        )
      },
      {
        selector: '.residents .upload-action',
        content: (
          <div>
            <h2>{i18n`Import Residents`}</h2>
            <span>{i18n`Import residents using a CSV file.`}</span><br />
            <span>{i18n`Download the CSV import template from the help section under the main menu.`}</span>
          </div>
        )
      },
      {
        selector: '.residents .download-action',
        content: (
          <div>
            <h2>{i18n`Download Residents`}</h2>
            <span>{i18n`Export all residents as a CSV file.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Resident Record`}</h2>
            <span>{i18n`Row with basic information about the resident. You can click on the row to get more details of the resident.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child .cell-units',
        content: (
          <div>
            <h2>{i18n`Assigned Units`}</h2>
            <span>{i18n`Here you will see units assigned to the resident.`}</span><br />
            <span>{i18n`You can unassign a unit from a resident by clicking on its X.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child td:last-child span',
        content: (
          <div>
            <h2>{i18n`Resident Activation`}</h2>
            <span>{i18n`You can activate or inactivate a resident by clicking this switch button.`}</span>
          </div>
        )
      },
      {
        selector: '.residents .guide-action',
        content: (
          <div>
            <h2>{i18n`Resident Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
