export default ({ palette, spacing }) => ({
  menuItem: {
    paddingTop: spacing(),
    paddingBottom: spacing(),
    height: 36
  },
  avatar: {
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.54)',
    backgroundColor: 'transparent'
  },
  addAvatar: {
    borderStyle: 'dashed'
  }
})
