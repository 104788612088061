import React, { Fragment, useReducer } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import AddIcon from '@material-ui/icons/Add'
import Avatar from '@material-ui/core/Avatar'
import toast from 'lib/toast'
import clsx from 'clsx'
import { withRouter, withTheme } from 'hocs'
import { CreateDialog } from 'components'
import Form from 'scenes/communities/form'
import getInitials from 'lib/initials'
import compose from 'lib/compose'
import slug from 'lib/slug'
import i18n from 'lib/i18n'
import { stores } from 'data'
import { view } from 'lib/store'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const CommunitySelector = ({ el, classes, open, onClose, location, match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    createDialog: false,
    domainDirty: false,
    isValid: false,
    errors: {},
    data: {
      title: '',
      domain: ''
    }
  })

  const reset = () => {
    setState({
      data: {
        title: '',
        domain: ''
      },
      errors: {},
      isValid: false,
      domainDirty: false
    })
  }

  const { isValid, createDialog } = state

  const create = async data => {
    try {
      await stores.communities.create(data)
      toast.success(i18n`Created`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to create community`)
    }
    reset()
  }

  const setDomainUrl = domain => {
    const { domain: oldDomain } = match.params
    if (oldDomain === domain) return
    const nextUrl = location.pathname.replace(oldDomain, domain)
    history.replace(nextUrl)
  }

  const onFormChange = (data, isValid, errors) => {
    let domainDirty = state.domainDirty

    if (data.domain !== state.data.domain) {
      domainDirty = true
    }

    data.domain = domainDirty ? data.domain : slug(data.title)
    setState({ data, isValid, errors, domainDirty })
  }

  const onCreateAction = ({ ok }) => {
    if (ok) create(state.data)
    else reset()
    closeCreateDialog()
  }

  const onChange = community => {
    stores.communities.setCommunity(community)
    props.onChange(community)
    setDomainUrl(community.domain)
  }

  const openCreateDialog = () => {
    onClose()
    setState({ createDialog: true })
  }

  const closeCreateDialog = () => {
    setState({ createDialog: false })
  }

  return (
    <Fragment>
      <Menu id="community-selector-menu" anchorEl={el} open={open} onClose={onClose}>
        {stores.communities.list.map(com => (
          <MenuItem
            key={com.domain}
            className={classes.menuItem}
            selected={com.domain === stores.communities.community.domain}
            onClick={() => onChange(com)}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{getInitials(com.title)}</Avatar>
            </ListItemAvatar>
            <ListItemText inset primary={com.title} />
          </MenuItem>
        ))}
        <MenuItem className={classes.menuItem} onClick={openCreateDialog}>
          <ListItemAvatar>
            <Avatar className={clsx(classes.avatar, classes.addAvatar)}>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText inset primary={i18n`Create community`} />
        </MenuItem>
      </Menu>
      <CreateDialog
        title={i18n`Create community`}
        open={createDialog}
        onAction={onCreateAction}
        okDisabled={!isValid}
      >
        <Form {...state} onChange={onFormChange} />
      </CreateDialog>
    </Fragment>
  )
}

CommunitySelector.efaultProps = {
  onChange: () => {},
  onCreate: () => {},
  onClose: () => {}
}

export default compose(
  withTheme('selector'),
  withRouter,
  view
)(CommunitySelector)
