import React, { Fragment, useEffect, useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import { PlaceHolder, Button, Table, CalendarImg, DeleteDialog } from 'components'
import { withTheme } from 'hocs'
import { mapStatus, mapTypes } from 'data/incident/maps'
import { SEARCH_KEYS } from 'data/incident/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import { timeAgo, truncate } from 'lib/utils'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const IncidentList = ({ classes, match, history }) => {
  const [state, setState] = useReducer(reducer, {
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)

  const { domain } = match.params
  const { list } = stores.incidents
  const { keyword, guide, deleteDialog } = state
  const data = keyword ? search(list, keyword) : list

  const columns = [
    { id: 'description', label: i18n`Description` },
    { id: 'type', label: i18n`Type` },
    { id: 'views', label: i18n`Views/Comments` },
    { id: 'status', label: i18n`Status` },
    { id: 'updated', label: i18n`Updated` }
  ]

  const selected = state.selected.map(id => ({ id }))

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    try {
      await stores.incidents.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching incidents`)
    }
  }

  const destroy = async data => {
    try {
      await stores.incidents.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.areas.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete incident(s)`)
    }
    reset()
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder media={() => <CalendarImg width="200" height="200" />}>
        <Typography variant="h5">{i18n`No incidents to show at this moment`}</Typography>
      </PlaceHolder>
    )
  }

  const renderRow = (node, key, data) => {
    if (key === 'updated') {
      node = <div className={classes.noWrap}>{timeAgo(data.updated)}</div>
    } else if (key === 'created') {
      node = <div className={classes.noWrap}>{timeAgo(data.created)}</div>
    } else if (key === 'type') {
      node = truncate(mapTypes(data.type), 50)
    } else if (key === 'description') {
      node = truncate(data.description, 50)
    } else if (key === 'views') {
      node = `${data.views}/${data.comments.length}`
    } else if (key === 'status') {
      node = (
        <Typography className={classes[`STATUS_${data.status}`]}>
          {mapStatus(node).toUpperCase()}
        </Typography>
      )
    }

    return node
  }

  return (
    <Fragment>
      <Table
        noInvite
        noAssign
        noSwitch
        noUpload
        noCheckbox
        noDownload
        noItemSwitch
        noItemDelete
        noSelectedDownload
        data={data}
        selected={state.selected}
        columns={columns}
        className="incidents"
        title={i18n`Incidents`}
        onRow={page}
        onDetail={page}
        onCreate={() => page('create')}
        onSearch={onSearch}
        onGuide={openGuide}
        onSelected={onSelected}
        onDelete={openDeleteDialog}
        placeHolder={renderPlaceHolder}
        row={renderRow}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete incidents`} onAction={onDeleteAction}>
        <p>
          {selected.length > 1
            ? i18n`Are you sure you want to delete the selected incidents?`
            : i18n`Are you sure you want to delete this incident?`}
        </p>
      </DeleteDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

// class IncidentList extends Component {
//   state = {
//     deleteDialog: false,
//     guide: false,
//     selected: [],
//     keyword: ''
//   }

//   search = createSearch(SEARCH_KEYS)

//   get domain() {
//     return this.props.match.params.domain
//   }

//   get data() {
//     const list = stores.incidents.list
//     const { keyword } = this.state
//     return keyword ? this.search(list, keyword) : list
//   }

//   get columns() {
//     return [
//       { id: 'description', label: i18n`Description` },
//       { id: 'type', label: i18n`Type` },
//       { id: 'views', label: i18n`Views/Comments` },
//       { id: 'status', label: i18n`Status` },
//       { id: 'updated', label: i18n`Updated` }
//     ]
//   }

//   get selected() {
//     return this.state.selected.map(id => ({ id }))
//   }

//   componentDidMount() {
//     this.fetch()
//   }

//   componentDidUpdate(prevProps) {
//     if (this.domain !== prevProps.match.params.domain) {
//       this.fetch()
//     }
//   }

//   reset = () => {
//     this.setState({ selected: [] })
//   }

//   fetch = async () => {
//     try {
//       await stores.incidents.fetch(this.domain)
//     } catch (error) {
//       toast.error(i18n`Unable to complete fetching incidents`)
//     }
//   }

//   destroy = async data => {
//     try {
//       await stores.incidents.destroy(this.domain, data)
//       toast.success(i18n`Deleted`)
//       stores.areas.fetch(this.domain, true)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to delete incident(s)`)
//     }
//     this.reset()
//   }

//   page = id => {
//     this.props.history.push(`${this.props.match.url}/${id}`)
//   }

//   onDeleteAction = ({ ok }) => {
//     if (ok) this.destroy(this.selected)
//     else this.reset()
//     this.closeDeleteDialog()
//   }

//   onSelected = selected => {
//     this.setState({ selected })
//   }

//   onSearch = keyword => {
//     this.setState({ keyword })
//   }

//   openDeleteDialog = () => {
//     this.setState({ deleteDialog: true })
//   }

//   closeDeleteDialog = () => {
//     this.setState({ deleteDialog: false })
//   }

//   openGuide = () => {
//     this.setState({ guide: true })
//   }

//   closeGuide = () => {
//     this.setState({ guide: false })
//   }

//   renderPlaceHolder = () => {
//     return (
//       <PlaceHolder
//         media={() => <CalendarImg width="200" height="200" />}
//         actions={() => (
//           <Fragment>
//             <Button onClick={() => this.page('create')}>{i18n`CREATE AN INCIDENT`}</Button>
//           </Fragment>
//         )}
//       >
//         <Typography variant="h5">{i18n`No incidents to show at this moment`}</Typography>
//       </PlaceHolder>
//     )
//   }

//   renderRow = (node, key, data) => {
//     const { classes } = this.props

//     if (key === 'updated') {
//       node = <div className={classes.noWrap}>{timeAgo(data.updated)}</div>
//     } else if (key === 'created') {
//       node = <div className={classes.noWrap}>{timeAgo(data.created)}</div>
//     } else if (key === 'type') {
//       node = truncate(mapTypes(data.type), 50)
//     } else if (key === 'description') {
//       node = truncate(data.description, 50)
//     } else if (key === 'views') {
//       node = `${data.views}/${data.comments.length}`
//     } else if (key === 'status') {
//       node = (
//         <Typography className={classes[`STATUS_${data.status}`]}>
//           {mapStatus(node).toUpperCase()}
//         </Typography>
//       )
//     }

//     return node
//   }

//   render() {
//     const { guide, selected, deleteDialog } = this.state
//     return (
//       <Fragment>
//         <Table
//           {...this.props}
//           noInvite
//           noAssign
//           noSwitch
//           noUpload
//           noCheckbox
//           noDownload
//           noItemSwitch
//           noItemDelete
//           noSelectedDownload
//           data={this.data}
//           selected={selected}
//           columns={this.columns}
//           className="incidents"
//           title={i18n`Incidents`}
//           onRow={this.page}
//           onDetail={this.page}
//           onCreate={() => this.page('create')}
//           onSearch={this.onSearch}
//           onGuide={this.openGuide}
//           onSelected={this.onSelected}
//           onDownload={this.onDownload}
//           onDelete={this.openDeleteDialog}
//           placeHolder={this.renderPlaceHolder}
//           row={this.renderRow}
//         />
//         <DeleteDialog
//           open={deleteDialog}
//           title={i18n`Delete incidents`}
//           onAction={this.onDeleteAction}
//         >
//           <p>
//             {selected.length > 1
//               ? i18n`Are you sure you want to delete the selected incidents?`
//               : i18n`Are you sure you want to delete this incident?`}
//           </p>
//         </DeleteDialog>
//         <Guide open={guide} onClose={this.closeGuide} />
//       </Fragment>
//     )
//   }
// }

export default compose(
  withTheme('incidents'),
  view
)(IncidentList)
