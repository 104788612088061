import React, { Fragment, useEffect, useReducer } from 'react'
import toast from 'lib/toast'
import { Section, DeleteDialog } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { pick } from 'lib/utils'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const CommunityDetail = ({ match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    deleteDialog: false
  })

  const fields = [
    'id',
    'title',
    'domain',
    'country',
    'province',
    'address',
    'active',
    'site',
    'email',
    'phone',
    'emergencyPhone'
  ]

  const { id } = match.params
  const community = stores.communities.get(id)

  const isSameData = fields.reduce((isSame, key) => {
    if (key === 'id' || key === 'domain') return isSame
    return isSame ? community[key] === state.data[key] : isSame
  }, true)

  useEffect(() => {
    fetch()
  }, [])

  const reset = () => {
    setState({
      data: {},
      errors: {},
      isValid: false
    })
  }

  const fetch = async () => {
    try {
      await stores.communities.fetch({ id })
    } catch (error) {
      toast.error(i18n`Unable to fetch community`)
    }
  }

  const update = async data => {
    try {
      await stores.communities.update(data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update community`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.communities.destroy(data)
      toast.success(i18n`Deleted`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete community`)
      }
    }
    reset()
  }

  const back = () => {
    history.push(`/communities`)
    return null
  }

  const onFormChange = (data, isValid, errors) => {
    data = pick(data, fields)
    setState({ data, isValid, errors })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(community)
    closeDeleteDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  if (!community) return back()

  const { isValid, deleteDialog } = state
  const data = { ...community, ...state.data }

  return (
    <Fragment>
      <Section
        {...props}
        noAssign
        data={data}
        title={i18n`Community Detail`}
        backButtonLabel={i18n`Communities`}
        onBack={back}
        onCancel={back}
        onSubmit={onUpdate}
        onDelete={openDeleteDialog}
        disableSubmitButton={!isValid || isSameData}
      >
        <Form isUpdate data={data} onChange={onFormChange} />
      </Section>
      <DeleteDialog open={deleteDialog} title={i18n`Delete community`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this community?`}</p>
      </DeleteDialog>
    </Fragment>
  )
}

export default view(CommunityDetail)
